import { ref } from 'vue';

import {
  AppFabButtonEnum,
  AppIconsEnum,
  GroupPageTabEnum,
  GroupsAccessEnum,
  PageToPostCreateEnum,
  PostTypeActionEnum,
  UserRoleEnum,
} from '@/enums';
import {
  canCreateIdeas,
  componentAdminRightMenuModal,
  componentCustomPageCreateModal,
  componentCustomPageInfoModal,
  componentPostCreateMobile,
  createPostAccessHelper,
  docBrowserOpenMenu,
  openPostCreateMenu,
  useAdminHelper,
  useCustomPages,
  usePostCreateMenu,
  useTaskManagement,
} from '@/helpers';
import { useI18n } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import {
  useAppStore,
  useCustomPageStore,
  useGroupsStore,
  useNetworkStore,
  usePostStore,
  useProjectsStore,
  useUserStore,
} from '@/store';
import type { GroupEntity, PageEntity, ProjectEntity } from '@/types';

export type FabData = {
  icon: string;
  value: AppFabButtonEnum | null;
  enabled: boolean;
  title?: string;
};

export type IUseFabBtnHelper = {
  /**
   * @description Array of objects with data for the FAB buttons
   * @todo Rewrite description and add more details
   */
  getFabData(isFocus: boolean /* isScrolled: boolean */): FabData[];
  /**
   * @description Handles the click event on the FAB button
   * @todo Rewrite description and add more details
   */
  handleFabClick(ev: Event, fab: AppFabButtonEnum): Promise<void>;
};

export const useFabBtn = (): IUseFabBtnHelper => {
  //* Router
  const route = router.currentRoute.value;
  const { id } = route.params;
  const { t } = useI18n();

  //* Store
  const appStore = useAppStore();
  const postStore = usePostStore();
  const pageStore = useCustomPageStore();
  const groupStore = useGroupsStore();
  const userStore = useUserStore();
  const networkStore = useNetworkStore();
  const projectsStore = useProjectsStore();

  //* Helpers
  const customPagesHelper = useCustomPages();
  const taskManagementHelper = useTaskManagement();
  const postCreateMenu = usePostCreateMenu();
  const adminHelper = useAdminHelper();

  const getFabData = (
    isFocus: boolean /* isScrolled: boolean */
  ): FabData[] => {
    //* Refs
    const isLGWidth = ref<boolean>(appStore.isLGWidth);
    const currentUserRoleId = ref<number>(userStore.current?.roleId ?? 0);
    const postsIsLoading = ref<boolean>(postStore.loading);
    const announcementsIsLoading = ref<boolean>(postStore.loading);
    const feedIsLoading = ref<boolean>(
      postsIsLoading.value || announcementsIsLoading.value
    );
    const groupData = ref<GroupEntity>(groupStore.getGroupById(Number(id)));
    const editablePage = ref<PageEntity | null>(pageStore.editablePage);
    const pageById = ref<PageEntity | null>(
      pageStore.getPageById(Number(id)) ?? null
    );
    const pageId = ref<number | null>(pageStore.getPageIdByGroupId(Number(id)));
    const isGroupAdmin = ref<boolean>(
      groupData.value.accessType === GroupsAccessEnum.Admin ||
        currentUserRoleId.value >= UserRoleEnum.SuperAdministrator
    );
    const showPostCreateFabInGroupPage = ref<boolean>(
      !isFocus &&
        createPostAccessHelper(
          PageToPostCreateEnum.Group,
          true,
          false,
          isGroupAdmin.value,
          groupData.value
        )
    );
    const adminPages = ref<string[]>(adminHelper.getAdminPages());

    const getItems = (): FabData[] => {
      switch (route.name) {
        case ROUTES_NAME.FEED: {
          const showPostCreateFab = ref<boolean>(
            createPostAccessHelper(PageToPostCreateEnum.Feed, true, false) ||
              (!isFocus &&
                createPostAccessHelper(
                  PageToPostCreateEnum.Feed,
                  true,
                  feedIsLoading.value
                ))
          );

          return [
            {
              icon: AppIconsEnum.Plus,
              value: AppFabButtonEnum.CreatePost,
              enabled: showPostCreateFab.value,
              title: t('feed.createPost'),
            },
          ];
        }

        case ROUTES_NAME.TOPIC_BY_ID: {
          const showPostCreateFabInTopicPage = ref<boolean>(
            createPostAccessHelper(
              PageToPostCreateEnum.Tag,
              true,
              postsIsLoading.value
            )
          );
          return [
            {
              icon: AppIconsEnum.Plus,
              value: AppFabButtonEnum.CreatePost,
              enabled: showPostCreateFabInTopicPage.value,
              title: t('feed.createPost'),
            },
          ];
        }

        /* case ROUTES_NAME.PROJECT_BY_ID:
      case ROUTES_NAME.TASKS:
      return {
        icon: AppIconsEnum.Plus,
        value: AppFabButtonEnum.CreateTask,
        enabled: taskManagementHelper.getAccessToCreateTask(),
      }; */

        /* case ROUTES_NAME.PROJECTS:
      return {
        icon: AppIconsEnum.Plus,
        value: AppFabButtonEnum.CreateProject,
        enabled: true,
      }; */

        /* case ROUTES_NAME.MILESTONES:
      return {
        icon: AppIconsEnum.Plus,
        value: AppFabButtonEnum.CreateMilestone,
        enabled: true,
      }; */

        case ROUTES_NAME.PAGES:
          return [
            {
              icon: AppIconsEnum.Plus,
              value: AppFabButtonEnum.CreatePage,
              enabled: true,
              title: t('create'),
            },
          ];

        case ROUTES_NAME.GROUP_DASHBOARD: {
          const accessToDashboardEdit = ref<boolean>(
            customPagesHelper.getAccessToPageEdit(editablePage.value, true)
          );
          return [
            {
              icon: AppIconsEnum.Information,
              value: AppFabButtonEnum.DashboardInfo,
              enabled: accessToDashboardEdit.value && !appStore.isMDWidth,
              title: t('customPage.info'),
            },
          ];
        }

        case ROUTES_NAME.PAGE_EDIT: {
          const accessToPageEdit = ref<boolean>(
            customPagesHelper.getAccessToPageEdit(editablePage.value, false)
          );
          return [
            {
              icon: AppIconsEnum.Information,
              value: AppFabButtonEnum.PageInfo,
              enabled: accessToPageEdit.value && !appStore.isMDWidth,
              title: t('customPage.info'),
            },
          ];
        }

        case ROUTES_NAME.PAGE_BY_ID: {
          const showPostCreateFabInCustomPage = ref<boolean>(
            !isFocus &&
              createPostAccessHelper(
                PageToPostCreateEnum.CustomPage,
                true,
                false,
                isGroupAdmin.value,
                undefined,
                pageById.value ?? undefined
              )
          );

          return [
            {
              icon: AppIconsEnum.Pencil,
              value: AppFabButtonEnum.EditPage,
              enabled: customPagesHelper.getAccessToPageEdit(
                pageById.value,
                false
              ),
              title: t('customPage.options.edit'),
            },
            {
              icon: AppIconsEnum.PencilSquare,
              value: AppFabButtonEnum.CreatePost,
              enabled: showPostCreateFabInCustomPage.value,
              title: t('feed.createPost'),
            },
          ];
        }

        case ROUTES_NAME.CALENDAR:
          return [
            {
              icon: AppIconsEnum.Plus,
              value: AppFabButtonEnum.CreateEvent,
              enabled: true,
              title: t('create'),
            },
          ];

        case ROUTES_NAME.GROUP_BY_ID: {
          const groupSelectedTab = ref<GroupPageTabEnum>(
            groupStore.selectedTab
          );
          switch (groupSelectedTab.value) {
            case GroupPageTabEnum.Dashboard: {
              const pageByGroup = ref<PageEntity | null>(
                pageId.value
                  ? (pageStore.getPageById(pageId.value) ?? null)
                  : null
              );

              const showPostCreateFabInGroupDashboard = ref<boolean>(
                !isFocus &&
                  createPostAccessHelper(
                    PageToPostCreateEnum.GroupDashboard,
                    true,
                    false,
                    isGroupAdmin.value,
                    groupData.value,
                    pageByGroup.value ?? undefined
                  )
              );

              return [
                {
                  icon: AppIconsEnum.Pencil,
                  value: AppFabButtonEnum.EditDashboard,
                  enabled: customPagesHelper.getAccessToPageEdit(
                    pageByGroup.value,
                    true
                  ),
                  title: t('customPage.options.editDashboard'),
                },
                {
                  icon: AppIconsEnum.PencilSquare,
                  value: AppFabButtonEnum.CreatePost,
                  enabled:
                    showPostCreateFabInGroupPage.value &&
                    showPostCreateFabInGroupDashboard.value,
                  title: t('feed.createPost'),
                },
              ];
            }

            case GroupPageTabEnum.Feed:
              return [
                {
                  icon: AppIconsEnum.Plus,
                  value: AppFabButtonEnum.CreatePost,
                  enabled: showPostCreateFabInGroupPage.value,
                  title: t('feed.createPost'),
                },
              ];

            case GroupPageTabEnum.Files:
              return [
                {
                  icon: AppIconsEnum.Plus,
                  value: AppFabButtonEnum.UploadDocument,
                  enabled:
                    groupData.value.accessType >= GroupsAccessEnum.Member,
                  title: t('files.addDocument'),
                },
              ];
            default:
              break;
          }
        }

        case ROUTES_NAME.DOCS: {
          const networkAllowPostToFeed = ref<boolean>(
            networkStore.settings?.allowPostToFeed ?? false
          );
          return [
            {
              icon: AppIconsEnum.Plus,
              value: AppFabButtonEnum.UploadDocument,
              enabled:
                networkAllowPostToFeed.value && route.path.includes('/docs'),
              title: t('files.addDocument'),
            },
          ];
        }

        case ROUTES_NAME.IDEAS: {
          return [
            {
              icon: AppIconsEnum.Plus,
              value: AppFabButtonEnum.CreatePost,
              enabled: canCreateIdeas(),
              title: t('create'),
            },
          ];
        }

        default: {
          const routeName = route.name as string;
          if (adminPages.value.includes(routeName)) {
            return [
              {
                icon: AppIconsEnum.Hamburger,
                value: AppFabButtonEnum.OpenAdminMenu,
                enabled: !isLGWidth.value,
              },
            ];
          }
          return [{ icon: AppIconsEnum.None, value: null, enabled: false }];
        }
      }
    };

    return getItems().filter((item) => item.enabled);
  };

  const handleFabClick = async (
    ev: Event,
    fab: AppFabButtonEnum
  ): Promise<void> => {
    const currentProject = ref<ProjectEntity>(projectsStore.getCurrentProject);
    const groupData = ref<GroupEntity>(groupStore.getGroupById(Number(id)));

    switch (fab) {
      case AppFabButtonEnum.CreatePost:
        await openPostCreateMenu(postCreateMenu.categories.value);
        break;

      case AppFabButtonEnum.CreateTask:
        await componentPostCreateMobile(PostTypeActionEnum.Task);
        break;

      case AppFabButtonEnum.CreateProject:
        await taskManagementHelper.taskManagementProjectSwitchHelper(
          ev,
          false,
          false,
          true
        );
        break;

      case AppFabButtonEnum.CreateMilestone:
        await taskManagementHelper.taskManagementMilestonesHelper(
          ev,
          null,
          null,
          currentProject.value.id,
          true
        );
        break;

      case AppFabButtonEnum.CreatePage:
        await componentCustomPageCreateModal();
        break;

      case AppFabButtonEnum.DashboardInfo: {
        if (pageStore.editablePage) {
          await componentCustomPageInfoModal(
            pageStore.editablePage?.pageId,
            true,
            Number(id)
          );
        }
        break;
      }

      case AppFabButtonEnum.EditDashboard:
        await router.push({
          name: ROUTES_NAME.GROUP_DASHBOARD,
          params: { id: groupData.value.id },
        });
        break;

      case AppFabButtonEnum.EditPage:
        await router.push({ name: ROUTES_NAME.PAGE_EDIT });
        break;

      case AppFabButtonEnum.PageInfo: {
        if (pageStore.editablePage) {
          await componentCustomPageInfoModal(
            pageStore.editablePage?.pageId,
            false
          );
        }
        break;
      }

      case AppFabButtonEnum.CreateEvent:
        await componentPostCreateMobile(PostTypeActionEnum.CalendarEvent);
        break;

      case AppFabButtonEnum.UploadDocument:
        await docBrowserOpenMenu(router, route, groupData.value.id ?? null);
        break;

      case AppFabButtonEnum.OpenAdminMenu:
        await componentAdminRightMenuModal();
        break;

      default:
        break;
    }
  };

  return {
    getFabData,
    handleFabClick,
  };
};
