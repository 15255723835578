import { cloneDeep, find, remove, unionBy } from 'lodash';
import { defineStore } from 'pinia';

import { useNetworkStore } from './network.pinia';

import { NetworkSettingsTypeEnum } from '@/enums';
import { changeFavicon } from '@/helpers';
import { defaultNetworkDesign, defaultNetworkSettings } from '@/models';
import { $api } from '@/services';
import type {
  DomainModel,
  DomainUpdateModel,
  ErrorMessageModel,
  NetworkBrandingModel,
  NetworkDesignModel,
  NetworkEmailFooterModel,
  NetworkFullSettingsModel,
  NetworkMobileAppsModel,
  NetworkSettingsModel,
  ResponseDomain,
  ResponseDomains,
  ResponseErrorModel,
  ResponseNetworkBrandingModel,
  ResponseNetworkDesignModel,
  ResponseNetworkEmailFooterModel,
  ResponseNetworkFullSettingsModel,
  ResponseNetworkMobileAppsModel,
} from '@/types';

export type AdminState = {
  errors: ErrorMessageModel[];
  loading: boolean;
  networkSettings: NetworkFullSettingsModel;
  domainList: { data: DomainModel[]; loadMoreUrl: string | null };
  design: NetworkDesignModel;
  emailFooter: string | null;
};

export const useAdminStore = defineStore({
  id: 'admin',
  state: (): AdminState => ({
    errors: [],
    loading: false,
    networkSettings: cloneDeep(defaultNetworkSettings),
    domainList: { data: [], loadMoreUrl: null },
    design: cloneDeep(defaultNetworkDesign),
    emailFooter: null,
  }),
  getters: {
    getDomainList: (state: AdminState) => state.domainList,
    getDesign: (state: AdminState): NetworkDesignModel => state.design,
    getEmailFooter: (
      state: AdminState
    ): NetworkEmailFooterModel['emailFooter'] => state.emailFooter,
  },
  actions: {
    async currentNetworkSettings(): Promise<boolean> {
      this.loading = true;
      const response = await $api.network.networkSettings();
      if (response.statusCode === 200) {
        const model = response as ResponseNetworkFullSettingsModel;
        this.networkSettings = cloneDeep(model.data);
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async mobileAppsSettings(): Promise<boolean> {
      this.loading = true;
      const response = await $api.network.mobileAppsSettings();
      if (response.statusCode === 200) {
        const model = response as ResponseNetworkMobileAppsModel;
        this.networkSettings = {
          ...this.networkSettings,
          ...cloneDeep(model.data),
        };
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async brandingSettings(): Promise<boolean> {
      this.loading = true;
      const response = await $api.network.brandingSettings();
      if (response.statusCode === 200) {
        const model = response as ResponseNetworkBrandingModel;
        this.networkSettings = {
          ...this.networkSettings,
          ...cloneDeep(model.data),
        };
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async saveNetworkSettings(
      data: NetworkSettingsModel,
      type: NetworkSettingsTypeEnum
    ): Promise<boolean> {
      this.errors = [];
      this.loading = true;
      const response = await $api.network.saveNetworkSettings(data, type);

      if (response.statusCode === 200) {
        const model = response as ResponseNetworkFullSettingsModel;
        const networkStore = useNetworkStore();
        networkStore.$patch({
          settings: {
            ...networkStore.settings,
            ...model.data,
          },
        });

        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    /**
     * TODO: Should be removed in favor of saveNetworkSettings that accepts [type: NetworkSettingsTypeEnum.Branding]
     * @obsolete
     * @see saveNetworkSettings
     */
    async saveBrandingSettings(data: NetworkBrandingModel): Promise<boolean> {
      this.errors = [];
      this.loading = true;
      const response = await $api.network.saveNetworkSettings(
        data,
        NetworkSettingsTypeEnum.Branding
      );

      if (response.statusCode === 200) {
        const model = response as ResponseNetworkFullSettingsModel;
        const networkStore = useNetworkStore();
        networkStore.$patch({
          settings: {
            ...networkStore.settings,
            ...model.data,
          },
        });

        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    /**
     * TODO: Should be removed in favor of saveNetworkSettings that accepts [type: NetworkSettingsTypeEnum.MobileApps]
     * @obsolete
     * @see saveNetworkSettings
     */
    async saveMobileAppsSettings(
      data: NetworkMobileAppsModel
    ): Promise<boolean> {
      this.errors = [];
      this.loading = true;
      const response = await $api.network.saveNetworkSettings(
        data,
        NetworkSettingsTypeEnum.MobileApps
      );

      if (response.statusCode === 200) {
        const model = response as ResponseNetworkFullSettingsModel;
        const networkStore = useNetworkStore();
        networkStore.$patch({
          settings: {
            ...networkStore.settings,
            ...model.data,
          },
        });

        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async domains(text?: string): Promise<boolean> {
      this.loading = true;
      const response = await $api.admin.getDomains(text);
      if (response.statusCode === 200) {
        const model = response as ResponseDomains;
        this.domainList.data = cloneDeep(model.data);
        this.domainList.loadMoreUrl = model.loadMoreUrl;
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async domainsLoadMore(url: string): Promise<boolean> {
      this.loading = true;
      const response = await $api.admin.domainsLoadMore(url);
      if (response.statusCode === 200) {
        const model = response as ResponseDomains;
        this.domainList.data = mergeDomainsByEmailSuffix(
          this.domainList.data,
          model.data
        );
        this.domainList.loadMoreUrl = model.loadMoreUrl;
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async createDomain(data: DomainModel): Promise<boolean> {
      this.loading = true;
      const response = await $api.admin.createDomain(data);
      if (response.statusCode === 200) {
        const model = response as ResponseDomain;
        this.domainList.data.push(model.data);
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async saveDomain(data: DomainUpdateModel): Promise<boolean> {
      this.loading = true;
      const response = await $api.admin.saveDomain(data);

      if (response.statusCode === 200) {
        const model = response as ResponseDomain;
        const index = this.domainList.data.findIndex(
          (n) => n.emailSuffix === data.oldEmailSuffix
        );
        if (~index) this.domainList.data[index] = model.data;
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async deleteDomain(data: DomainModel): Promise<boolean> {
      this.loading = true;
      const response = await $api.admin.deleteDomain(data);
      if (response.statusCode === 200) {
        remove(this.domainList.data, { emailSuffix: data.emailSuffix });
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async emailFooterSettings(): Promise<boolean> {
      this.loading = true;
      const response = await $api.network.emailFooterSettings();
      if (response.statusCode === 200) {
        const model = response as ResponseNetworkEmailFooterModel;
        this.emailFooter = model.data;
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async saveEmailFooter(emailFooter: string | null): Promise<boolean> {
      this.loading = true;
      const response = await $api.network.saveEmailFooter(emailFooter);
      if (response.statusCode === 200) {
        const model = response as ResponseNetworkEmailFooterModel;
        this.emailFooter = cloneDeep(model.data);

        const networkStore = useNetworkStore();
        networkStore.$patch((state) => {
          if (state.settings) {
            state.settings.emailFooter = model.data;
          }
        });
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async designSettings(): Promise<boolean> {
      this.loading = true;
      const response = await $api.network.designSettings();
      if (response.statusCode === 200) {
        const model = response as ResponseNetworkDesignModel;
        this.design = cloneDeep(model.data);
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    /**
     * TODO: Should be removed in favor of saveNetworkSettings that accepts [type: NetworkSettingsTypeEnum.Style]
     * @obsolete
     * @see saveNetworkSettings
     */
    async saveStyle(data: NetworkDesignModel): Promise<boolean> {
      this.loading = true;
      const response = await $api.network.saveNetworkSettings(
        data,
        NetworkSettingsTypeEnum.Style
      );
      if (response.statusCode === 200) {
        const model = response as ResponseNetworkDesignModel;
        this.design = cloneDeep(model.data);

        const networkStore = useNetworkStore();
        networkStore.$patch({
          settings: {
            ...networkStore.settings,
            ...model.data,
          },
        });

        changeFavicon();

        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
  },
  persist: true,
});

const mergeDomainsByEmailSuffix = (a: DomainModel[], b: DomainModel[]) => {
  return unionBy(a, b, 'emailSuffix').map((obj) => {
    const match = find(b, { emailSuffix: obj.emailSuffix });
    return match ? Object.assign({}, obj, match) : obj;
  });
};
