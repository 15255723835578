import axios from '@/services/axios';
import { useWikiStore } from '@/store';
import type { UserShortModel, WikiHistoricalModel } from '@/types';

export type ToolbarGroup = {
  name?: string;
  items: string[];
};

export type IUseRichTextEditor = {
  /**
   * @description Get time ago from now based on the given date
   * @param date - Date string
   * @returns
   * @deprecated
   */
  getTimeAgo(date: string): string;
  /**
   * @description Prepare texts for API call
   * @param targetWiki - Target wiki
   * @param sourceWiki - Source wiki
   * @returns Prepared texts for API call
   * @todo Implement _getHtmlLinks
   */
  prepareForApiCall(
    targetWiki: WikiHistoricalModel,
    sourceWiki: WikiHistoricalModel
  ): { targetText: string; sourceText: string } | null;
  /**
   * @description Get complete wiki from version ID
   * @param versionId - Version ID
   * @returns Complete wiki of type WikiHistoricalModel or null
   */
  getCompleteWikiFromVersionId(
    versionId: number
  ): Promise<WikiHistoricalModel | null>;
  /**
   * @description Preprocess internal links
   * @param element - HTML element
   * @returns void
   * @todo Refactor
   */
  preprocessInternalLinks(element: HTMLElement): void;
  /**
   * @description Restore internal links
   * @param element - HTML element
   * @returns void
   * @todo Refactor
   */
  restoreInternalLinks(element: HTMLElement): void;
  /**
   * @description Preprocess video
   * @param element - HTML element
   * @returns void
   * @todo Refactor
   */
  preprocessVideo(element: HTMLElement): Promise<void>;
  /**
   * @description Restore video processing
   * @param element - HTML element
   * @returns void
   * @todo Refactor
   */
  restoreVideoProcessing(element: HTMLElement): void;
  /**
   * @description Preprocess body
   * @param htmlString - HTML string
   * @returns Promise of string
   * @todo Refactor
   */
  preprocessBody(htmlString: string): Promise<string>;
  /**
   * @description Pre-submit
   * @param htmlString - HTML string
   * @returns string
   * @todo Refactor
   */
  preSubmit(htmlString: string): string;
  /**
   * @description Get TinyMCE plugins
   * @param isSimpleMode - Is simple mode
   * @returns Array of strings
   */
  getPlugins(isSimpleMode?: boolean): string[];
  /**
   * @description Get TinyMCE toolbar
   * @param isSimpleMode - Is simple mode
   * @returns Array of ToolbarGroup
   */
  getToolbar(isSimpleMode?: boolean): ToolbarGroup[];
};

export const useRichTextEditor = (): IUseRichTextEditor => {
  //* Private methods
  /**
   * @description Get hidden HTML element
   * @param htmlString - HTML string
   * @returns Hidden HTML element
   * @todo Refactor
   */
  const _getHiddenElement = (htmlString: string): HTMLElement => {
    const parsedJson = JSON.parse(JSON.stringify(htmlString));
    const tempElement = document.createElement('div');
    tempElement.style.visibility = 'hidden';
    tempElement.innerHTML = parsedJson;

    return tempElement;
  };

  /**
   * @description Convert HTML to plain text by removing HTML tags
   * @param text - HTML text
   * @returns Plain text
   */
  const _convertHtml = (text: string): string => {
    return text
      .replace(/<br\s*\/?>/gi, '\r\n') // Replace <br> or <br/> with new lines
      .replace(/<[^>]+>/g, '') // Remove HTML tags
      .replace(/&nbsp;/g, ' '); // Replace non-breaking spaces with regular spaces
  };

  /**
   * @description Convert users to links
   * @param users - Users to convert to links
   * @returns HTML links
   * @todo Implement parsing of <a></a> from WikiComparePage.vue
   * @see /src/views/Wikis/WikiComparePage.vue
   */
  const _getHtmlLinks = (users: UserShortModel[]): string => {
    try {
      if (!users || users.length === 0) return '';

      let htmlLinks = '';

      const parseUser = (user: UserShortModel): string => {
        return `<a href="/user/${user.id}" style="color: var(--ion-color-intra); id="user_link_${user.id}">${'@' + user.fullName}</a> `;
      };

      users.forEach((user) => {
        htmlLinks += parseUser(user);
      });

      return htmlLinks;
    } catch (error) {
      console.error('Failed to get html links:', error);
      return '';
    }
  };

  /**
   * @description Convert wiki to advanced format
   * @param wiki - WikiHistoricalModel
   * @returns joined string of wiki content
   */
  const _convertToAdvanced = (wiki: WikiHistoricalModel): string => {
    /**
     * @description Assigning wikiContent or content to local variable
     * @obsolete - content
     * @see src/@types/wiki.d.ts
     * @todo Remove when API is updated
     */
    const finalContent = wiki.wikiContent ?? wiki.content;

    const name = wiki.name;
    const headTitle = finalContent?.head?.name ?? '';
    const headText = finalContent?.head?.text ?? '';
    const contentTitle = finalContent?.content?.name ?? '';
    const contentText = finalContent?.content?.text ?? '';

    /* Checking if wiki is Simple or Advanced */
    let bodyTitles;
    let bodyTexts;
    if (!finalContent) {
      bodyTitles = '';
      bodyTexts = wiki.wikiText ?? wiki.text ?? '';
    } else {
      bodyTitles = finalContent?.body?.map((b) => b.name).join('\n') ?? '';
      bodyTexts = finalContent?.body?.map((b) => b.text).join('\n') ?? '';
    }

    const participantsName = finalContent?.participants?.name ?? '';

    return `
      ${name}\n
      ${headTitle}\n
      ${headText}\n
      ${contentTitle}\n
      ${contentText}\n
      ${bodyTitles}\n
      ${bodyTexts}\n
      ${participantsName}
    `;
  };

  //* Public methods
  const getTimeAgo = (date: string) => {
    const currentDate = new Date();
    const modifyDate = new Date(date);
    const timeDifference =
      (currentDate.getTime() - modifyDate.getTime()) / 60000;

    let timeAgoFromNow;
    switch (true) {
      case timeDifference <= 1:
        timeAgoFromNow = 'just now';
        break;
      case timeDifference < 60:
        timeAgoFromNow = `${Math.floor(timeDifference)} minute${Math.floor(timeDifference) === 1 ? '' : 's'} ago`;
        break;
      case timeDifference < 1440:
        timeAgoFromNow = `${Math.floor(timeDifference / 60)} hour${Math.floor(timeDifference / 60) === 1 ? '' : 's'} ago`;
        break;
      case timeDifference < 10080:
        timeAgoFromNow = `${Math.floor(timeDifference / 1440)} day${
          Math.floor(timeDifference / 1440) === 1 ? '' : 's'
        } ago`;
        break;
      case timeDifference < 40320:
        timeAgoFromNow = `${Math.floor(timeDifference / 10080)} week${
          Math.floor(timeDifference / 10080) === 1 ? '' : 's'
        } ago`;
        break;
      case timeDifference < 525600:
        timeAgoFromNow = `${Math.floor(timeDifference / 40320)} month${
          Math.floor(timeDifference / 40320) === 1 ? '' : 's'
        } ago`;
        break;
      default:
        timeAgoFromNow = 'a long time ago';
        break;
    }

    return timeAgoFromNow;
  };

  const prepareForApiCall = (
    targetWiki: WikiHistoricalModel,
    sourceWiki: WikiHistoricalModel
  ): { targetText: string; sourceText: string } | null => {
    try {
      /* Target */
      const joinedTargetText = _convertToAdvanced(targetWiki);

      /* Source */
      const joinedSourceText = _convertToAdvanced(sourceWiki);

      /*
        TODO: Implement
      */
      /* Convert users to links */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const targetLinks = targetWiki.wikiContent?.participants?.users
        ? _getHtmlLinks(targetWiki.wikiContent.participants.users)
        : '';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const sourceLinks = sourceWiki.wikiContent?.participants?.users
        ? _getHtmlLinks(sourceWiki.wikiContent.participants.users)
        : '';

      /* Gather final texts + links */
      // const finalTargetText = _convertHtml(joinedTargetText) + targetLinks;
      // const finalSourceText = _convertHtml(joinedSourceText) + sourceLinks;

      /* Convert users to @names */
      const targetNames = targetWiki.wikiContent?.participants.users
        ?.map((u) => '@' + u.fullName)
        .join('\n');
      const sourceNames = sourceWiki.wikiContent?.participants.users
        ?.map((u) => '@' + u.fullName)
        .join('\n');

      /* Gather final texts + @names */
      const finalTargetText = _convertHtml(joinedTargetText) + targetNames;
      const finalSourceText = _convertHtml(joinedSourceText) + sourceNames;

      // Checking if the final texts aren't empty
      if (!finalTargetText || !finalSourceText) {
        console.error(
          'Failed to convert the text: finalOriginalText or finalUpdatedText is empty'
        );
        return null;
      }

      return {
        targetText: finalTargetText,
        sourceText: finalSourceText,
      };
    } catch (error) {
      console.error('Failed to prepare for API call:', error);
      return null;
    }
  };

  const getCompleteWikiFromVersionId = async (
    versionId: number
  ): Promise<WikiHistoricalModel | null> => {
    const wikiStore = useWikiStore();

    try {
      const alreadyAvailable = wikiStore.historicalWikis.find(
        (w) => w.id === versionId
      );

      if (alreadyAvailable) return alreadyAvailable;

      const completeWiki = await wikiStore.getHistoricalWiki(versionId);
      return completeWiki ?? null;
    } catch (error) {
      console.error('Failed to get complete wiki from version id:', error);
      return null;
    }
  };

  const preprocessInternalLinks = (element: HTMLElement): void => {
    const pattern = /^[ugt]:\d+$/;
    const internalLinks = Array.from(element.querySelectorAll('a')).filter(
      (link) => pattern.test(link.getAttribute('href') || '')
    );

    for (const link of internalLinks) {
      const href = link.getAttribute('href');
      const [identifier, id] = href?.split(':') as unknown as string;
      const routerNameMapper = {
        u: `/user/${id}`,
        g: `/group/${id}`,
        t: `/topic/${id}`,
      };
      link.setAttribute(
        'href',
        routerNameMapper[identifier as 'u' | 'g' | 't']
      );
    }
  };

  const restoreInternalLinks = (element: HTMLElement): void => {
    const routerPathMatcher = /^\/(user|group|topic)\/(\d+)$/;
    const internalLinks = Array.from(element.querySelectorAll('a')).filter(
      (link) => routerPathMatcher.test(link.getAttribute('href') || '')
    );

    for (const link of internalLinks) {
      const href = link.getAttribute('href');
      const matchResult = href?.match(routerPathMatcher);
      if (matchResult) {
        const [, routerName, id] = matchResult;
        const routerIdentifierMapper = {
          '/user': 'u',
          '/group': 'g',
          '/topic': 't',
        };
        const identifier =
          routerIdentifierMapper[
            `/${routerName}` as '/user' | '/group' | '/topic'
          ];
        if (identifier) {
          link.setAttribute('href', `${identifier}:${id}`);
        }
      }
    }
  };

  const preprocessVideo = async (element: HTMLElement): Promise<void> => {
    const medias = element.querySelectorAll('video');

    for (const media of medias) {
      const sourceElement = media.querySelector('source');

      if (sourceElement) {
        const videoUrl = sourceElement.getAttribute('src');

        const response = await axios.get(videoUrl as string, {
          responseType: 'blob',
        });

        sourceElement.setAttribute('data-original-src', videoUrl as string);
        const blobUrl = URL.createObjectURL(response as unknown as Blob);
        sourceElement.setAttribute('src', blobUrl);
      }
    }
  };

  const restoreVideoProcessing = (element: HTMLElement): void => {
    const medias = element.querySelectorAll('video');

    for (const media of medias) {
      const sourceElement = media.querySelector('source');

      if (sourceElement) {
        const originalVideoUrl =
          sourceElement.getAttribute('data-original-src') ||
          sourceElement.getAttribute('alt');

        if (originalVideoUrl) {
          const regex = /filename=([^&]+)/;
          const match = originalVideoUrl.match(regex);

          if (match?.[1]) {
            const filename = match[1];

            sourceElement.setAttribute('src', `i:${filename}`);
            sourceElement.removeAttribute('data-original-src');
          }
        }
      }
    }
  };

  const preprocessBody = async (htmlString: string): Promise<string> => {
    // console.log('≥≥≥preprocessBody - htmlString:', htmlString); //! DEBUG
    const tempElement = _getHiddenElement(htmlString);

    /*
    TODO: delete if images get without auth token
    const imageTags = tempElement.querySelectorAll('img');

    for (const image of imageTags) {
      try {
        const response = await axios.get(image.alt, {
          responseType: 'blob',
        });

        const blobUrl = URL.createObjectURL(response as unknown as Blob);
        image.setAttribute('data-remote-url', image.alt);
        image.setAttribute('src', blobUrl);
      } catch (error) {
        console.error('Image fetch error:', error);
      }
    }
    */

    preprocessInternalLinks(tempElement);
    await preprocessVideo(tempElement);
    // console.log('≥≥≥preprocessBody - tempElement:', tempElement); //! DEBUG
    return tempElement.innerHTML;
  };

  const preSubmit = (htmlString: string): string => {
    const tempElement = _getHiddenElement(htmlString);

    const imageTags = tempElement.querySelectorAll('img');

    for (const image of imageTags) {
      const remoteUrl =
        image.getAttribute('data-remote-url') || image.getAttribute('alt');

      const regex = /\/media\/image/;
      const match = regex.test(remoteUrl || '');

      if (match) {
        image.setAttribute('src', remoteUrl as string);
        image.removeAttribute('data-remote-url');
      }
    }

    restoreInternalLinks(tempElement);
    restoreVideoProcessing(tempElement);
    return tempElement.innerHTML;
  };

  // https://www.tiny.cloud/docs/tinymce/latest/available-toolbar-buttons/#the-core-toolbar-buttons
  const getPlugins = (isSimpleMode = false): string[] => {
    return [
      'accordion',
      'autolink',
      'insertdatetime',
      'searchreplace',
      'advlist',
      'autoresize',
      'code',
      'link',
      'nonbreaking',
      'table',
      'wordcount',
      'anchor',
      'codesample',
      'fullscreen',
      'importcss',
      'lists',
      'pagebreak',
      'save',
      /**
       * Potential plugins to remove
       */
      'charmap',
      'visualchars',
      'visualblocks',
      'directionality',
      ...(!isSimpleMode ? ['media', 'preview', 'image'] : []),
    ];
  };

  // https://www.tiny.cloud/docs/tinymce/latest/available-toolbar-buttons/#the-core-toolbar-buttons
  /**
   * GetToolbar returns a simplified toolbar configuration without certain features.
   *
   * @param isSimpleMode If true, returns a simplified toolbar; otherwise includes advanced tools.
   * @returns Array of toolbar groups.
   */
  const getToolbar = (isSimpleMode = false): ToolbarGroup[] => {
    return [
      { name: 'history', items: ['undo', 'redo'] },
      { name: 'styles', items: ['blocks'] },
      {
        name: 'formatting',
        items: ['bold', 'italic', 'removeformat'],
      },
      {
        name: 'alignment',
        items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
      },
      { name: 'indentation', items: ['outdent', 'indent'] },
      { name: 'lists', items: ['bullist', 'numlist'] },
      ...(!isSimpleMode
        ? [
            { name: 'media', items: ['link', 'image', 'media'] },
            { name: 'advanced', items: ['table', 'fullscreen'] },
          ]
        : []),
    ];
  };

  /**
   * Full toolbar configuration with advanced options.
   *
   * This version contains additional items that were previously disabled.
   * Uncomment this function to enable more features when needed.
   */
  /**
   *
    const getToolbar = (isSimpleMode = false): ToolbarGroup[] => {
      return [
        { name: 'history', items: ['undo', 'redo'] },
        { name: 'styles', items: ['blocks'] },
        {
          name: 'formatting',
          items: ['bold', 'italic', 'underline', 'strikethrough', 'removeformat'], // #472 - 17.07.24 - Jakov
        },
        { name: 'colors', items: ['forecolor', 'backcolor'] }, // #472 - 17.07.24 - Jakov
        {
          name: 'alignment',
          items: [
            'alignleft',
            'aligncenter',
            'alignright',
            'alignjustify',
            'alignnone', // #472 - 17.07.24 - Jakov
          ],
        },
        { name: 'indentation', items: ['outdent', 'indent'] },
        { name: 'lists', items: ['bullist', 'numlist'] },
        {
          name: 'fonts',
          items: ['fontfamily', 'fontsize', 'fontsizeinput'], // #472 - 17.07.24 - Jakov
        },
        { name: 'language', items: ['language', 'lineheight'] }, // #472 - 17.07.24 - Jakov
        { name: 'headings', items: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }, // #472 - 17.07.24 - Jakov
        { name: 'misc', items: ['selectall', 'remove', 'visualaid'] }, // #472 - 17.07.24 - Jakov
        ...(!isSimpleMode
        ? [
          { name: 'media', items: ['link', 'image', 'media'] },
          { name: 'advanced', items: ['table', 'fullscreen'] },
          { name: 'insert', items: ['hr', 'blockquote'] }, // #472 - 17.07.24 - Jakov
          { name: 'document', items: ['newdocument', 'print'] }, // #472 - 17.07.24 - Jakov
          { name: 'clipboard', items: ['cut', 'copy', 'paste', 'pastetext'] }, // #472 - 17.07.24 - Jakov
          { name: 'advanced', items: ['table', 'codesample', 'fullscreen'] }, // #472 - 17.07.24 - Jakov
          { name: 'scripts', items: ['subscript', 'superscript'] }, // #472 - 17.07.24 - Jakov
        ]
        : []),
      ];
    };
  */

  return {
    getTimeAgo,
    prepareForApiCall,
    getCompleteWikiFromVersionId,
    preprocessInternalLinks,
    restoreInternalLinks,
    preprocessVideo,
    restoreVideoProcessing,
    preprocessBody,
    preSubmit,
    getPlugins,
    getToolbar,
  };
};
