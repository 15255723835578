<template>
  <app-row
    class="link ion-align-items-center"
    @click.stop="goToLink(linkData.url)"
  >
    <app-col v-if="linkData.urlImage" size="auto" class="link-image">
      <ion-img :src="linkData.urlImage"></ion-img>
    </app-col>
    <app-col v-else size="auto" class="link-icon">
      <ion-icon :src="icons.arrow"></ion-icon>
    </app-col>
    <app-col class="link-text">
      <ion-text class="title">{{ linkData.title }}</ion-text>
      <ion-text class="url">{{ linkData.url }}</ion-text>
      <ion-text v-if="linkData.description" class="description">{{
        linkData.description
      }}</ion-text>
    </app-col>
  </app-row>
</template>

<script lang="ts" setup>
import { IonText, IonImg, IonIcon } from '@ionic/vue';
import { arrowUndoOutline } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { AppRow, AppCol } from '@/components';
import type { PostAttachedLinkModel } from '@/types';

const icons = {
  arrow: arrowUndoOutline,
};
const props = defineProps({
  linkData: {
    type: Object as PropType<PostAttachedLinkModel>,
    required: true,
  },
});

const linkData: ComputedRef<PostAttachedLinkModel> = computed(
  () => props.linkData
);
const goToLink = (link: string) => {
  window.open(link);
};
</script>
<style scoped lang="scss">
.link {
  padding: 0.5rem;
  border: 1px solid var(--ion-color-light-custom);
  border-radius: 4px;
}
.link:hover {
  cursor: pointer;
  opacity: 0.7;
}
.link .link-image {
  padding-right: 0.5rem;
}
.link .link-image ion-img::part(image) {
  border-radius: 4px;
  object-fit: cover;
  width: 50px;
  max-height: 50px;
}
.link .link-icon {
  padding-right: 0.5rem;
}
.link .link-icon ion-icon {
  font-size: 2rem;
  color: var(--ion-color-medium);
}
.link .link-text .title {
  display: block;
  font-weight: bold;
}
.link .link-text .url {
  color: var(--ion-color-medium);
}
.link .link-text .description {
  color: var(--ion-color-medium);
  margin-top: 0.5rem;
  font-size: 0.8rem;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
