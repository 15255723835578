import type { OverlayEventDetail } from '@ionic/core';
import { alertController } from '@ionic/vue';
import { filter } from 'lodash';
import type { Ref, ComputedRef } from 'vue';
import { computed, ref } from 'vue';

import ArchiveLink from '../../assets/icon/archive-link.svg';
import Autoplay from '../../assets/icon/autoplay.svg';
import DocVersions from '../../assets/icon/doc-versions.svg';
import Download from '../../assets/icon/download.svg';
import Eye from '../../assets/icon/eye.svg';
import GitlabLink from '../../assets/icon/gitlab_link.svg';
import History from '../../assets/icon/history.svg';
import Move from '../../assets/icon/move.svg';
import NotificationsOff from '../../assets/icon/notifications-off.svg';
import Notifications from '../../assets/icon/notifications.svg';
import PencilSquare from '../../assets/icon/pencil-square.svg';
import Remove from '../../assets/icon/remove.svg';
import Rename from '../../assets/icon/rename.svg';
import Retry from '../../assets/icon/retry.svg';
import Share from '../../assets/icon/share.svg';
import StarO from '../../assets/icon/star-o.svg';
import Star from '../../assets/icon/star.svg';
import Upload from '../../assets/icon/upload.svg';
import Users from '../../assets/icon/users.svg';

import {
  AccessByRoleEnum,
  ActionAccessEnum,
  AppIconsEnum,
  DocumentTypeEnum,
  FileMenuActionEnum,
  FileStatusEnum,
  GroupsTypeEnum,
  ShareArchiveLinkType,
  UploadFileTypes,
  UserRoleEnum,
} from '@/enums';
import {
  canSendArchivedLink,
  componentDocsUploadFile,
  componentFileFollowersModal,
  componentFileHistoryModal,
  componentFileRelations,
  componentImagesViewer,
  componentShareArchiveLink,
  componentTitleChange,
  filesHybrid,
  shareFileHelper,
  showToast,
  useOfficeHelper,
  componentDocsMoveFile,
} from '@/helpers';
import { useI18n } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import {
  useDocStore,
  useGroupsStore,
  useUserStore,
  useNetworkStore,
} from '@/store';
import type {
  FileModel,
  DocEntity,
  DocsMenuItemModel,
  FolderModel,
  GroupEntity,
  WikiModel,
} from '@/types';

export type IUseFileActions = {
  isImage: (file: FileModel) => Promise<boolean>;
  isVideo: (file: FileModel) => boolean;
  isAudio: (file: FileModel) => boolean;
  isOffice: (file: FileModel) => boolean;
  isPDF: (file: FileModel) => boolean;
  isPreviewAvailable: (file: FileModel) => Promise<boolean>;
  isFileVersion: (file: FileModel | null) => boolean;
  isFileModelGuard: (file: FileModel) => boolean;
  checkIfOutdated: (file: FileModel | null) => boolean;
  imageView: (file: FileModel) => void;
  openFile: (file: FileModel) => Promise<void>;
  editFile: (file: FileModel) => Promise<void>;
  downloadFile: (
    file: FileModel,
    status?: Ref<FileStatusEnum>
  ) => Promise<void>;
  renameFile: (file: FileModel) => Promise<void>;
  moveFile: (file: FileModel) => Promise<void>;
  deleteFile: (file: FileModel) => Promise<void>;
  uploadNewVersion: (file: FileModel) => Promise<void>;
  restoreFileVersion: (file: FileModel) => void;
  goToCurrentVersion: (file: FileModel) => void;
  getDocsFileMenuItems: (
    file: DocEntity,
    toolbarMenu?: boolean
  ) => DocsMenuItemModel[];
  whichActionToMake: (
    action: FileMenuActionEnum,
    file: FileModel,
    status?: Ref<FileStatusEnum>
  ) => Promise<void>;
};

export const useFileActions = (): IUseFileActions => {
  const { t } = useI18n();
  const docStore = useDocStore();
  const officeHelper = useOfficeHelper();

  const fileExtensionsNotForPdfPreview: Ref<string[]> = ref([
    'mp3',
    'tiff',
    'pbm',
    'tga',
    'mp4',
  ]);

  const isOffice = (file: FileModel): boolean =>
    officeHelper.isOfficeFile(file.type);

  const isImage = async (file: FileModel): Promise<boolean> => {
    if (!file.mimeType.startsWith('image')) {
      return false;
    }

    const isSupported = await filesHybrid.isImageFormatSupported(
      file.image?.url ?? ''
    );

    if (!isSupported) {
      await showToast(t('files.imageNotSupported'), false);
      return false;
    }

    return true;
  };

  const isVideo = (file: FileModel): boolean =>
    file.mimeType.startsWith('video');

  const isAudio = (file: FileModel): boolean => filesHybrid.fileIsAudio(file);

  const isPDF = (file: FileModel): boolean =>
    file.type === 'pdf' &&
    !fileExtensionsNotForPdfPreview.value.includes(file.type);

  const isPreviewAvailable = async (file: FileModel): Promise<boolean> =>
    (await isImage(file)) ||
    isVideo(file) ||
    isAudio(file) ||
    isPDF(file) ||
    isOffice(file);

  const isFileVersion = (file: FileModel | null): boolean => !file?.createdAt;

  const imageView = async (file: FileModel) => {
    await componentImagesViewer(0, [{ file: file, text: '' }]);
  };

  const openFile = async (file: FileModel): Promise<void> => {
    {
      if (await isImage(file)) {
        await router.push({
          name: ROUTES_NAME.FILE_BY_ID,
          params: { id: file.id },
        });
        return;
      }

      const result = await filesHybrid.openFile(file);

      if (result === FileStatusEnum.Error) {
        await showToast(t('errorResponse'), false);
      }
    }
  };

  const editFile = async (file: FileModel): Promise<void> => {
    if (isOffice(file)) {
      const result = (await officeHelper.openOfficeView(
        file.id.toString(),
        false,
        {
          title: file.name,
          publishedBy: file.author,
          group: file.group,
          type: file.type,
        },
        true,
        false,
        file.group?.id ?? null,
        file.parentFolderId ?? null
      ))
        ? FileStatusEnum.Success
        : FileStatusEnum.Error;

      if (result === FileStatusEnum.Success) {
        await docStore.updateCurrentFile(true);
      }

      if (result === FileStatusEnum.Error) {
        await showToast(t('files.fileFailedOpenforEdit'), false);
        return;
      }
    }
  };

  const downloadFile = async (
    file: FileModel,
    status?: Ref<FileStatusEnum>
  ): Promise<void> => {
    if (status?.value) {
      status.value = FileStatusEnum.Loading;
    }
    const downloadFileResult = await filesHybrid.downloadFile(file);
    if (status?.value) {
      status.value = downloadFileResult;
    }

    await showToast(
      downloadFileResult === FileStatusEnum.Success
        ? t('files.successDownloaded')
        : t('files.failedDownloaded'),
      downloadFileResult === FileStatusEnum.Success
    );
  };

  const renameFile = async (file: FileModel): Promise<void> => {
    const result = await componentTitleChange(
      null,
      file.name,
      true,
      file?.description || ''
    );

    if (!result.data) {
      console.error('Failed to rename file');
      return;
    }

    const renameFileResult = (await docStore.renameFile(
      file.id,
      result.data.title,
      result.data.text
    ))
      ? FileStatusEnum.Success
      : FileStatusEnum.Error;

    await showToast(
      renameFileResult === FileStatusEnum.Success
        ? t('files.fileSuccessRenamed')
        : t('files.fileFailedRenamed'),
      renameFileResult === FileStatusEnum.Success
    );
  };

  const moveFile = async (file: FileModel): Promise<void> => {
    const result = await componentDocsMoveFile(null);

    if (!result.data) {
      console.error('Failed to move file');
      return;
    }

    const moveFileResult = (await docStore.moveFile(
      result.data.folderId,
      result.data.groupId,
      file.id
    ))
      ? FileStatusEnum.Success
      : FileStatusEnum.Error;

    await showToast(
      moveFileResult === FileStatusEnum.Success
        ? t('files.fileSuccessMoved')
        : t('files.fileFailedMoved'),
      moveFileResult === FileStatusEnum.Success
    );
  };

  const deleteFile = async (file: FileModel): Promise<void> => {
    let deleteFileResult: FileStatusEnum | null = null;
    const alert = await alertController.create({
      message: `${t('documents.popup.deleteFile')} <strong>${file.name}?</strong>`,
      buttons: [
        {
          text: t('no'),
          role: 'cancel',
          cssClass: 'custom-alert_buttons',
        },
        {
          text: t('yes'),
          role: 'confirm',
          cssClass: 'custom-alert_buttons',
          handler: async () => {
            deleteFileResult = (await docStore.deleteFile(file.id))
              ? FileStatusEnum.Success
              : FileStatusEnum.Error;
          },
        },
      ],
    });

    await alert.present();
    alert.onDidDismiss().then(async (event: OverlayEventDetail) => {
      if (event.role !== 'confirm') {
        return;
      }
      if (router.currentRoute.value.name === ROUTES_NAME.FILE_BY_ID) {
        router.back();
      }
      await showToast(
        deleteFileResult === FileStatusEnum.Success
          ? t('files.fileSuccessDeleted')
          : t('files.fileFailedDeleted'),
        deleteFileResult === FileStatusEnum.Success
      );
    });
  };

  const uploadNewVersion = async (file: FileModel): Promise<void> => {
    const result = await componentDocsUploadFile(
      UploadFileTypes.SingleAnyFile,
      true,
      file,
      true
    );
    if (result === undefined) {
      return;
    }

    const uploadNewVersionResult = result
      ? FileStatusEnum.Success
      : FileStatusEnum.Error;

    await showToast(
      uploadNewVersionResult === FileStatusEnum.Success
        ? t('files.fileSuccessUploaded')
        : t('files.fileFailedUploaded'),
      uploadNewVersionResult === FileStatusEnum.Success
    );
  };

  const _showHistory = async (id: number): Promise<void> => {
    const result = await docStore.getFileHistory(id);
    await componentFileHistoryModal();

    if (!result) {
      await showToast(t('files.fileFailedGetHistory'), false);
    }
  };

  const restoreFileVersion = async (file: FileModel) => {
    if (file.fileId) {
      const result = await docStore.restoreFileVersion(file.fileId, file.id);
      if (!result) {
        await showToast(t('files.fileFailedRestoreVersion'), false);
        return;
      }
      await showToast(t('files.fileSuccessRestoreVersion'), true);
      await goToCurrentVersion(file);
    }
  };

  const goToCurrentVersion = async (file: FileModel) => {
    if (file.fileId) {
      docStore.$patch((state) => {
        state.currentFileVersion = null;
      });
      await docStore.getCurrentFile(file.fileId);
    }
  };

  const _showRelations = async (id: number): Promise<void> => {
    await docStore.getRelations(id);
    await componentFileRelations();
  };

  const _markOfficial = async (id: number): Promise<void> => {
    await docStore.markOfficial(id);
  };

  const _follow = async (id: number): Promise<void> => {
    await docStore.follow(id);
  };

  const _unfollow = async (id: number): Promise<void> => {
    await docStore.unfollow(id);
  };

  const _showFollowers = async (id: number): Promise<void> => {
    await docStore.getFollowers(id);
    await componentFileFollowersModal();
  };

  function isFileModelGuard(
    data: WikiModel | FolderModel | FileModel
  ): data is FileModel {
    return (data as FileModel).type !== undefined;
  }

  const checkIfOutdated = (file: FileModel | null): boolean => {
    if (!file) {
      return false;
    }

    const currentFileEditedAt =
      (docStore.currentFile &&
        isFileModelGuard(docStore.currentFile.data) &&
        docStore.currentFile.data.editedAt) ||
      null;

    if (!currentFileEditedAt) {
      return false;
    }

    return (
      new Date(file.editedAt).getTime() <
      new Date(currentFileEditedAt).getTime()
    );
  };

  const getDocsFileMenuItems = (
    file: DocEntity,
    toolbarMenu = false
  ): DocsMenuItemModel[] => {
    const { t } = useI18n();

    // Store
    const docStore = useDocStore();
    const userStore = useUserStore();
    const groupStore = useGroupsStore();

    // Variables
    const activeGroup: ComputedRef<GroupEntity | null> = computed(
      () => docStore.activeGroup ?? null
    );

    const groupData: ComputedRef<GroupEntity> = computed(() =>
      groupStore.getGroupById(Number(activeGroup.value))
    );

    const currentUserId: ComputedRef<number> = computed(
      () => userStore.current?.id ?? 0
    );

    const currentUserRoleId: ComputedRef<number> = computed(
      () => userStore.current?.roleId ?? 0
    );

    const isGroupAdmin: ComputedRef<boolean> = computed(
      () =>
        groupData.value.adminIds.includes(currentUserId.value) ||
        currentUserRoleId.value >= UserRoleEnum.SuperAdministrator
    );

    const isCurrentRoutePreview: ComputedRef<boolean> = computed(
      () => router.currentRoute.value.name === ROUTES_NAME.FILE_BY_ID
    );

    const canShare = computed(() => {
      if (
        activeGroup.value &&
        groupData?.value.type === GroupsTypeEnum.PrivateHidden
      ) {
        return isGroupAdmin.value;
      }
      return true;
    });

    const isFile: boolean = file.documentType === DocumentTypeEnum.File;
    const isFolder: boolean = file.documentType === DocumentTypeEnum.Folder;
    const isWiki: boolean = file.documentType === DocumentTypeEnum.Wiki;
    const isFileVersion: boolean =
      file.documentType === DocumentTypeEnum.FileVersion;

    let isOffice = false;
    let isPDF = false;
    let isImage = false;
    let isAudio = false;

    if (isFileModelGuard(file.data)) {
      isOffice = useOfficeHelper().isOfficeFile(file.data.type);
      isPDF = file.data.type === 'pdf';
      isImage = file.data.mimeType.startsWith('image');
      isAudio = filesHybrid.fileIsAudio(file.data);
    }

    const toolbarMenuItems = [
      {
        title: isAudio ? t('files.menu.listen') : t('files.menu.view'),
        icon: isAudio ? AppIconsEnum.Autoplay : AppIconsEnum.Eye,
        svgIconPath: isAudio ? Autoplay : Eye,
        disabled: !(isFile || isImage),
        value: FileMenuActionEnum.Open,
      },
      {
        title: t('files.menu.download'),
        icon: AppIconsEnum.Download,
        svgIconPath: Download,
        disabled: false,
        value: FileMenuActionEnum.Download,
      },
      {
        title: t('files.menu.share'),
        icon: AppIconsEnum.Share,
        svgIconPath: Share,
        disabled: !(
          isFile &&
          file.data.access.includes(ActionAccessEnum.Share) &&
          canShare
        ),
        value: FileMenuActionEnum.Share,
      },
      {
        title: t(`files.menu.showFollowers`),
        value: FileMenuActionEnum.ShowFollowers,
        icon: AppIconsEnum.Users,
        svgIconPath: Users,
        disabled: false,
      },
    ] as DocsMenuItemModel[];

    const menuItems = [
      {
        title: isAudio ? t('files.menu.listen') : t('files.menu.view'),
        icon: isAudio ? AppIconsEnum.Autoplay : AppIconsEnum.Eye,
        svgIconPath: isAudio ? Autoplay : Eye,
        disabled:
          // (isCurrentRoutePreview.value && isSMWidth.value) || TODO uncomment later
          !isFile || (isImage && isCurrentRoutePreview.value),
        value: FileMenuActionEnum.Open,
      },
      {
        title: t('files.menu.edit'),
        icon: AppIconsEnum.PencilSquare,
        svgIconPath: PencilSquare,
        disabled: !(
          isFile &&
          isOffice &&
          file.data.access.includes(ActionAccessEnum.Edit)
        ),
        value: FileMenuActionEnum.Edit,
      },
      {
        title: t('files.menu.download'),
        icon: AppIconsEnum.Download,
        svgIconPath: Download,
        disabled: !isFile && !isFileVersion,
        /* TODO: uncomment later - disabled if button displayed in toolbar in preview page
        disabled: isCurrentRoutePreview.value && isSMWidth.value && !isFileVersion,
        */
        value: FileMenuActionEnum.Download,
      },
      {
        title: t('files.menu.goToCurrentVersion'),
        icon: AppIconsEnum.History,
        svgIconPath: History,
        disabled: !isFileVersion,
        value: FileMenuActionEnum.GoToCurrentVersion,
      },
      {
        title: t('files.menu.restore'),
        icon: AppIconsEnum.Retry,
        svgIconPath: Retry,
        disabled: !isFileVersion,
        value: FileMenuActionEnum.Restore,
      },
      {
        title: t('files.menu.share'),
        icon: AppIconsEnum.Share,
        svgIconPath: Share,
        // (isCurrentRoutePreview.value && isSMWidth.value) || TODO uncomment later
        disabled: !(
          isFile &&
          file.data.access.includes(ActionAccessEnum.Share) &&
          canShare
        ),
        value: FileMenuActionEnum.Share,
      },
      {
        title: t('feed.conversationPostMenu.send.shareArchiveLink'),
        icon: AppIconsEnum.ArchiveLink,
        svgIconPath: ArchiveLink,
        disabled: !(
          (isFile || isFolder || isWiki) &&
          file.data.access.includes(ActionAccessEnum.Share) &&
          canShare &&
          canSendArchivedLink()
        ),
        value: FileMenuActionEnum.ShareArchiveLink,
      },
      {
        title: t('files.menu.rename'),
        icon: AppIconsEnum.Rename,
        svgIconPath: Rename,
        disabled:
          isFileVersion || !file.data.access.includes(ActionAccessEnum.Rename),
        value: FileMenuActionEnum.Rename,
      },
      {
        title: t(`files.menu.showRelations`),
        value: FileMenuActionEnum.ShowRelations,
        icon: AppIconsEnum.GitlabLink,
        svgIconPath: GitlabLink,
        disabled: isFileVersion,
      },
      {
        title: t('files.menu.move'),
        icon: AppIconsEnum.Move,
        svgIconPath: Move,
        disabled:
          isFileVersion || !file.data.access.includes(ActionAccessEnum.Move),
        value: FileMenuActionEnum.Move,
      },
      {
        title: t(`files.menu.showFollowers`),
        value: FileMenuActionEnum.ShowFollowers,
        icon: AppIconsEnum.Users,
        svgIconPath: Users,
        // (isCurrentRoutePreview.value && isSMWidth.value) || TODO uncomment later
        disabled: isFileVersion,
      },
      {
        title:
          isFileModelGuard(file.data) && file.data.isFollowed
            ? t(`subscribe.unfollow`)
            : t(`subscribe.follow`),
        value:
          isFileModelGuard(file.data) && file.data.isFollowed
            ? FileMenuActionEnum.Unfollow
            : FileMenuActionEnum.Follow,
        icon:
          isFileModelGuard(file.data) && file.data.isFollowed
            ? AppIconsEnum.NotificationsOff
            : AppIconsEnum.Notifications,
        svgIconPath:
          isFileModelGuard(file.data) && file.data.isFollowed
            ? NotificationsOff
            : Notifications,
        disabled:
          // (isCurrentRoutePreview.value && isSMWidth.value) || TODO uncomment later
          isFileVersion,
      },
      {
        title: t(`files.menu.official`),
        value: FileMenuActionEnum.MarkOfficial,
        icon:
          isFileModelGuard(file.data) && file.data.isOfficial
            ? AppIconsEnum.Star
            : AppIconsEnum.StarOutline,
        svgIconPath:
          isFileModelGuard(file.data) && file.data.isOfficial ? Star : StarO,
        disabled: !(
          isFile && file.data.access.includes(ActionAccessEnum.MarkAsOfficial)
        ),
      },
      {
        title: t(`files.menu.showHistory`),
        value: FileMenuActionEnum.ShowHistory,
        icon: AppIconsEnum.DocVersions,
        svgIconPath: DocVersions,
        disabled: !(accessToSeeDocHistory() && isFile && (isOffice || isPDF)),
      },
      {
        title: t('appPopoverMenu.delete.title'),
        icon: AppIconsEnum.Remove,
        svgIconPath: Remove,
        disabled:
          isFileVersion || !file.data.access.includes(ActionAccessEnum.Delete),
        value: FileMenuActionEnum.Delete,
      },
      {
        title: t('files.menu.newVersion'),
        icon: AppIconsEnum.Upload,
        svgIconPath: Upload,
        disabled: !(
          isFile && file.data.access.includes(ActionAccessEnum.NewVersion)
        ),
        value: FileMenuActionEnum.NewVersion,
      },
    ] as DocsMenuItemModel[];

    return toolbarMenu
      ? filter(toolbarMenuItems, (n) => !n.disabled)
      : filter(menuItems, (n) => !n.disabled);
  };

  const whichActionToMake = async (
    action: FileMenuActionEnum,
    file: FileModel,
    status?: Ref<FileStatusEnum>
  ): Promise<void> => {
    switch (action) {
      case FileMenuActionEnum.Open:
        await openFile(file);
        break;

      case FileMenuActionEnum.Edit:
        await editFile(file);
        break;

      case FileMenuActionEnum.Download:
        await downloadFile(file, status);
        break;

      case FileMenuActionEnum.Rename:
        await renameFile(file);
        break;

      case FileMenuActionEnum.Move:
        await moveFile(file);
        break;

      case FileMenuActionEnum.Share:
        await shareFileHelper(file.id);
        break;

      case FileMenuActionEnum.ShareArchiveLink:
        await componentShareArchiveLink(file.id, ShareArchiveLinkType.File);
        break;

      case FileMenuActionEnum.Delete:
        await deleteFile(file);
        break;

      case FileMenuActionEnum.ShowHistory:
        await _showHistory(file.id);
        break;

      case FileMenuActionEnum.ShowRelations:
        await _showRelations(file.id);
        break;

      case FileMenuActionEnum.NewVersion:
        await uploadNewVersion(file);
        break;

      case FileMenuActionEnum.Restore:
        await restoreFileVersion(file);
        break;

      case FileMenuActionEnum.GoToCurrentVersion:
        await goToCurrentVersion(file);
        break;

      case FileMenuActionEnum.MarkOfficial:
        await _markOfficial(file.id);
        break;

      case FileMenuActionEnum.Follow:
        await _follow(file.id);
        break;

      case FileMenuActionEnum.Unfollow:
        await _unfollow(file.id);
        break;

      case FileMenuActionEnum.ShowFollowers:
        await _showFollowers(file.id);
        break;

      default:
        break;
    }
  };

  const accessToSeeDocHistory = (): boolean => {
    const userStore = useUserStore();
    const networkStore = useNetworkStore();

    const currentUserRoleId = userStore.current?.roleId ?? 0;
    const allowSeeDocHistory =
      networkStore.settings?.allowSeeDocHistory ?? AccessByRoleEnum.Off;

    const accessLevels = {
      [AccessByRoleEnum.Off]: false,
      [AccessByRoleEnum.AllUsers]: currentUserRoleId >= UserRoleEnum.User,
      [AccessByRoleEnum.ModeratorOrHigher]:
        currentUserRoleId >= UserRoleEnum.Moderator,
      [AccessByRoleEnum.AdminOrHigher]:
        currentUserRoleId >= UserRoleEnum.Administrator,
    };

    return accessLevels[allowSeeDocHistory] ?? false;
  };

  return {
    isImage,
    isVideo,
    isAudio,
    isOffice,
    isPDF,
    isFileVersion,
    isPreviewAvailable,
    downloadFile,
    renameFile,
    moveFile,
    deleteFile,
    uploadNewVersion,
    restoreFileVersion,
    goToCurrentVersion,
    whichActionToMake,
    editFile,
    openFile,
    imageView,
    getDocsFileMenuItems,
    isFileModelGuard,
    checkIfOutdated,
  };
};
