<template>
  <div
    v-if="feedType === FeedFilterTypeEnum.Announcement"
    class="block head-announcement"
    :class="{ 'item-in-feed': inFeed }"
  >
    <feed-announcement
      v-if="post.messageType === FeedTypeEnum.Announcement"
      :announcement-data="post"
      :feed-type="FeedTypeEnum.Announcement"
      :feed-flag="feedFlag"
    >
      <feed-user-item
        :group="post.group"
        :author="post.author"
        :initiator="post.initiator"
        :created-at="post.createdAt"
        :message-type="post.messageType"
        :is-pinned="post.isPinned"
      />
    </feed-announcement>
  </div>

  <div
    v-else
    v-intersection-observer="onIntersectionObserver"
    class="post-item"
    :class="{
      'post-page': feedFlag === FeedFlagEnum.ConversationPage,
      'with-shadow': post.messageType === FeedTypeEnum.Announcement,
      'item-in-feed': inFeed,
    }"
    @click.stop="openPost(post.id)"
  >
    <div
      class="block"
      :class="{
        announcement: post.messageType === FeedTypeEnum.Announcement,
      }"
    >
      <div class="information-bar">
        <feed-user-item
          v-if="post.messageType !== FeedTypeEnum.Announcement"
          :group="post.group"
          :author="post.author"
          :initiator="post.initiator"
          :created-at="post.createdAt"
          :planned-date="post.plannedPostData?.plannedDate"
          :message-type="post.messageType"
          :is-pinned="post.isPinned"
        />

        <feed-menu-button
          v-if="post.messageType !== FeedTypeEnum.Announcement"
          :post="post"
          :feed-flag="feedFlag"
          :feed-type="feedType"
        />
      </div>
      <div class="message">
        <feed-text
          v-if="post.messageType === FeedTypeEnum.Text"
          class="text-content"
          :text-data="post.bodyHtml"
          :feed-flag="feedFlag"
        />

        <feed-users
          v-if="
            post.ccUsers.length > 0 && post.messageType !== FeedTypeEnum.Event
          "
          :users-data="post.ccUsers"
        />

        <feed-announcement
          v-if="post.messageType === FeedTypeEnum.Announcement"
          :announcement-data="post"
          :feed-flag="feedFlag"
        >
          <div class="information-bar">
            <feed-user-item
              :group="post.group"
              :author="post.author"
              :initiator="post.initiator"
              :created-at="post.createdAt"
              :planned-date="post.plannedPostData?.plannedDate"
              :message-type="post.messageType"
              :is-pinned="post.isPinned"
            />
            <feed-menu-button
              :post="post"
              :feed-flag="feedFlag"
              :feed-type="feedType"
            />
          </div>
        </feed-announcement>
      </div>
      <feed-group-created
        v-if="post.messageType === FeedTypeEnum.GroupCreated"
        :group-created-data="post.bodyHtml"
        :feed-flag="feedFlag"
      />
      <feed-group-deleted
        v-if="post.messageType === FeedTypeEnum.GroupDeleted"
        :group-deleted-data="post.bodyHtml"
        :feed-flag="feedFlag"
      />
      <feed-new-user
        v-if="post.messageType === FeedTypeEnum.UserCreated"
        :new-user-data="post.bodyHtml"
        :feed-flag="feedFlag"
      />
      <feed-shared-messages
        v-if="post.sharedMessages.count > 0"
        :shared-messages-data="post.sharedMessages"
        :feed-flag="feedFlag"
        :feed-type="feedType"
        :parent-post-id="post.id"
      />
      <feed-badge
        v-if="
          post.messageType === FeedTypeEnum.Badge && post.attachedBadge !== null
        "
        :badge-data="post.attachedBadge"
        :text="post.bodyHtml"
        :feed-flag="feedFlag"
      />

      <feed-idea
        v-if="post.ideaData !== null"
        :idea-data="post.ideaData"
        :likes-data="post.likes"
        :feed-flag="feedFlag"
        :parent-post-id="parentPostId"
        :post-id="post.id"
      />

      <feed-event
        v-if="post.eventData !== null"
        :event-data="post.eventData"
        :users="post.ccUsers"
        :text-data="post.bodyHtml"
        :post-id="post.id"
        :author="post.author"
        :feed-flag="feedFlag"
        :parent-post-id="parentPostId"
      />

      <feed-poll
        v-if="post.pollData !== null"
        :poll-data="post.pollData"
        :post-id="post.id"
        :feed-flag="feedFlag"
        :parent-post-id="parentPostId"
      />

      <feed-task
        v-if="post.messageType === FeedTypeEnum.Task && post.taskData"
        :task-data="post.taskData"
        :post-id="post.id"
        :feed-flag="feedFlag"
      />

      <div
        :class="{
          'announcement-padding':
            post.messageType === FeedTypeEnum.Announcement,
        }"
      >
        <feed-wiki
          v-if="post.attachedWikis.count > 0"
          :wiki-data="post.attachedWikis.data"
          :created-at="post.createdAt"
        />

        <feed-folder
          v-if="post.attachedFolders.count > 0"
          :folder-data="post.attachedFolders.data"
        />

        <feed-files
          v-if="post.attachedFiles.count > 0"
          :files-data="post.attachedFiles.data"
          :post-date="post.createdAt"
        />

        <feed-files
          v-if="
            post.plannedPostData !== null &&
            post.plannedPostData?.tempFiles?.length
          "
          :files-data="post.plannedPostData.tempFiles"
          :post-date="post.createdAt"
        />

        <feed-topics :post="post" />
      </div>

      <feed-link
        v-if="post.attachedLink !== null && post.attachedLink.title"
        class="mb-lg"
        :link-data="post.attachedLink"
      />

      <div
        v-if="feedFlag !== FeedFlagEnum.SharedPost"
        :class="{
          'announcement-padding':
            post.messageType === FeedTypeEnum.Announcement,
        }"
      >
        <feed-controls-buttons
          :access="post.access"
          :only-count="!isSMWidth"
          :data="post"
          :type-for-buttons="FeedTypeForButtonsEnum.Post"
          :feed-flag="feedFlag"
          @openCommentField="openField($event)"
        />

        <feed-comments
          v-if="
            post.comments.data.length > 0 &&
            post.messageType !== FeedTypeEnum.Task
          "
          :comments-data="post.comments"
          :post-id="post.id"
          :current-user-id="currentUserId"
          :feed-flag="feedFlag"
          :feed-type="feedType"
          @onCommentReply="commentReply"
        />

        <feed-message-send
          v-if="
            (post.comments.count > 0 || commentFieldFlag === post.id) &&
            post.access.includes(ActionAccessEnum.Comment) &&
            post.messageType !== FeedTypeEnum.Task
          "
          ref="sendRef"
          :post-id="post.id"
          :post-data="post"
          :post-type="PostTypeActionEnum.Comment"
          @onCommentFocus="commentFocus"
        />
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="
          post?.isRead === false &&
          post.sharedMessages.count === 0 &&
          !post.plannedPostData
        "
        class="unread-indicator"
      >
        {{ $t('new') }}
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { vIntersectionObserver } from '@vueuse/components';
import debounce from 'lodash/debounce';
import { ref, computed } from 'vue';
import type { ComputedRef, PropType } from 'vue';
import 'swiper/css';
import { useRoute, useRouter } from 'vue-router';

import {
  FeedEvent,
  FeedComments,
  FeedPoll,
  FeedAnnouncement,
  FeedGroupCreated,
  FeedGroupDeleted,
  FeedWiki,
  FeedFiles,
  FeedIdea,
  FeedBadge,
  FeedSharedMessages,
  FeedControlsButtons,
  FeedText,
  FeedLink,
  FeedMessageSend,
  FeedUsers,
  FeedNewUser,
  FeedUserItem,
  FeedMenuButton,
  FeedTopics,
  FeedFolder,
  FeedTask,
} from '@/components';
import {
  FeedTypeForButtonsEnum,
  FeedFlagEnum,
  FeedFilterTypeEnum,
  FeedTypeEnum,
  PostTypeActionEnum,
  ActionAccessEnum,
} from '@/enums';
import { ROUTES_NAME } from '@/router';
import { useAppStore, usePostStore, useUserStore } from '@/store';
import type { CommentsDataModel, PostEntity } from '@/types';

import 'swiper/css/pagination';
import 'swiper/css/navigation';

const props = defineProps({
  post: {
    type: Object as PropType<PostEntity>,
    required: true,
  },
  feedFlag: {
    type: String as PropType<FeedFlagEnum>,
    required: true,
  },
  feedType: {
    type: String as PropType<FeedFilterTypeEnum | null>,
    default: FeedFilterTypeEnum.Recommended,
  },
  parentPostId: {
    type: Number || null,
    default: () => null,
  },
});
const route = useRoute();
const router = useRouter();

// Store
const userStore = useUserStore();
const appStore = useAppStore();
const postStore = usePostStore();

// Variables
const inFeed = computed(() => route.name === ROUTES_NAME.FEED);
const commentFieldFlag = ref<number>(0);
const sendRef = ref<any | null>(null);
const isSMWidth: ComputedRef<boolean> = computed(() => appStore.isSMWidth);
const currentUserId: ComputedRef<number> = computed(
  () => userStore.current?.id ?? 0
);

// Actions
const openField = async (postId: number) => {
  commentFieldFlag.value = postId;
  await setCommentFieldFocus();
};

const setCommentFieldText = debounce(async (comment: CommentsDataModel) => {
  if (sendRef.value !== null) {
    await sendRef.value.setFieldText(comment);
  }
}, 0);

const setCommentFieldFocus = debounce(async () => {
  if (sendRef.value !== null) {
    await sendRef.value.setFieldFocus();
  }
}, 0);

const openPost = async (id: number) => {
  if (route.name === ROUTES_NAME.POST_BY_ID) {
    return;
  }

  await router.push({
    name: ROUTES_NAME.POST_BY_ID,
    params: { id: id },
  });
};

const emit = defineEmits(['onCommentFocus']);
const commentFocus = (focus: boolean) => {
  emit('onCommentFocus', focus);
};

const commentReply = async (comment: CommentsDataModel) => {
  await setCommentFieldFocus();
  await setCommentFieldText(comment);
};

const onIntersectionObserver = ([
  { isIntersecting },
]: IntersectionObserverEntry[]) => {
  const caseToMarkAsRead =
    props.feedFlag !== FeedFlagEnum.ForceToReadModal &&
    !props.post.plannedPostData &&
    isIntersecting &&
    props.post.id > 0 &&
    'isRead' in props.post &&
    props.post.isRead === false;

  if (caseToMarkAsRead) {
    postStore.$patch({
      postsToMarkAsRead: [...postStore.postsToMarkAsRead, props.post.id],
    });
  }
};
</script>

<style scoped lang="scss">
div.post-item {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  background: var(--ion-color-light-background-contrast);
  border-radius: app-radius(md);
  position: relative;
  .unread-indicator {
    z-index: 1;
    position: absolute;
    top: calc(app-padding(md) * (-1));
    right: 0;
    height: app-padding(lg);
    background: var(--ion-color-notification-badge);
    border-radius: app-radius(sm);
    font-size: 0.7rem;
    color: white;
    display: flex;
    align-items: center;
    padding-inline: app-padding(lg);
  }
}

div.post-item.item-in-feed {
  box-sizing: border-box;
}
.item-in-feed:hover {
  cursor: pointer;
  background-color: var(--ion-color-custom-post-background);
  border-radius: app-radius(md);
}
.with-shadow {
  -webkit-box-shadow: 0 0 8px 3px rgba(var(--ion-color-intra-rgb), 0.1);
  -moz-box-shadow: 0 0 8px 3px rgba(var(--ion-color-intra-rgb), 0.1);
  box-shadow: 0 0 8px 3px rgba(var(--ion-color-intra-rgb), 0.1);
}
.announcement-padding {
  padding: 0 app-padding(lg) app-padding(lg) app-padding(lg);
}
.block {
  padding: app-padding(lg);
  width: 100%;
  position: relative;
}
.block.head-announcement {
  padding: unset;
  padding-bottom: 4rem;
}
.block.announcement {
  padding: unset;
}
.post-page .block {
  margin-bottom: 0;
}
.text-content {
  margin-top: 0.5rem;
}
.information-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@include fadeLeaveTransition;
</style>
