<template>
  <div
    class="app-header-menu"
    :style="{
      'grid-template-columns': `repeat(${menuItems.length}, minmax(min-content, 7rem))`,
    }"
  >
    <div
      v-for="(item, index) in activeMenuItems"
      v-show="item.enabled"
      :key="`menu-item_${index}`"
      class="item"
      :class="{
        active: item.isActive,
      }"
      @click="menuItemClick($event, item)"
    >
      <transition name="fade">
        <ion-badge
          v-if="item.name === AppMenuEnum.Messenger && messagesCount > 0"
          mode="md"
          class="counter"
        >
          {{ messagesCount > 99 ? `${'99+'}` : messagesCount }}
        </ion-badge>
      </transition>
      <icons-provider
        class="icon"
        :icon-props="{
          width: '24',
          height: '24',
          fill: 'var(--ion-color-custom)',
        }"
        :name="item.icon"
      />
      <ion-label class="fw-400">{{ item.title }}</ion-label>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonLabel, IonBadge } from '@ionic/vue';
import type { ComputedRef } from 'vue';
import { computed, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import { IconsProvider } from '@/components';
import { AppMenuEnum } from '@/enums';
import {
  useMenu,
  componentAppSubMenuPopover,
  componentLoginModal,
} from '@/helpers';
import { useMessengerStore } from '@/store';
import type { AppMenuItem } from '@/types';

// Extend AppMenuItem to include isActive property
type ExtendedAppMenuItem = AppMenuItem & { isActive: boolean };

// Store
const messengerStore = useMessengerStore();

// Router
const router = useRouter();
const route = useRoute();

// Helpers
const menuHelper = useMenu();

// Refs
const activeMenuItems = ref<ExtendedAppMenuItem[]>([]);

// Computed
const menuItems: ComputedRef<AppMenuItem[]> = computed(() =>
  menuHelper.getHeaderMenu()
);
const messagesCount: ComputedRef<number> = computed(
  () => messengerStore.getUnreadMessagesCount
);

// Methods
const isActive = (element: AppMenuItem): boolean => {
  const caseOne = !route.params?.id && element.link?.name === route.name;
  const caseTwo =
    !!route.params?.id &&
    !!element.link?.params?.id &&
    +route.params.id === element.link?.params.id &&
    element.link?.name === route.name;

  return caseOne || caseTwo;
};

// Watchers
watch(
  [menuItems, route],
  () => {
    activeMenuItems.value = menuItems.value.map((item) => ({
      ...item,
      isActive: isActive(item),
    }));
  },
  { immediate: true }
);

// Methods
const menuItemClick = async (
  ev: MouseEvent,
  item: AppMenuItem
): Promise<void> => {
  if (item.submenu?.length) {
    await componentAppSubMenuPopover(ev, item.submenu, 'bottom');
    return;
  }

  if (item.href && (await menuHelper.validateCustomLink(item.href))) {
    window.open(new URL(item.href), '_blank');
    return;
  }

  if (item.link) {
    await router.push({
      name: item.link.name,
      params: item.link.params,
      query: ev.button === 1 ? { newTab: 'true' } : {},
    });
    return;
  }

  if (item.name === AppMenuEnum.Login) {
    await componentLoginModal();
    return;
  }
};
</script>

<style scoped lang="scss">
.app-header-menu {
  display: grid;
  gap: 0;
  height: calc((var(--ion-safe-area-bottom) / 2) + 58px);
  width: 100%;
  z-index: 1;
  padding-bottom: calc(var(--ion-safe-area-bottom) / 2);
  .item {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all 0.15s ease-in-out;
    flex-direction: column;
    color: var(--ion-color-custom);
    padding-top: 8px;
    padding-bottom: 4px;
    overflow: hidden;
    justify-content: center;
    ion-label {
      font-size: 0.9rem;
      white-space: nowrap;
    }
    .icon {
      margin-bottom: 4px;
    }
    .counter {
      top: 0.4rem;
      right: calc(50% - 0.5rem);
      transform: translateX(100%);
      position: absolute;
      padding: 2px 5px;
      border-radius: 1rem;
      color: var(--ion-color-custom);
      z-index: 1;
      font-size: 0.7rem;
      border-radius: 2rem;
      min-width: 1.3rem;
      --background: var(--ion-color-notification-badge);
    }
    &:hover {
      cursor: pointer;
      background: rgba(var(--ion-color-light-rgb), 0.1);
    }
    &.active {
      position: relative;
      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 3px;
        background: var(--ion-color-custom);
        position: absolute;
        bottom: 0;
        border-radius: 1.5px;
      }
    }
  }
}
</style>
