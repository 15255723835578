<template>
  <div :class="['comments-block', { 'with-border': postCommentData }]">
    <!-- Если это пост -->
    <template v-if="postCommentData">
      <div
        class="author-avatar"
        @click.stop="openProfile(postCommentData!.author.id)"
      >
        <user-avatar
          :fio="postCommentData.author.fullName"
          :url="postCommentData.author.image?.url ?? ''"
          :image="postCommentData.author.image"
        />
      </div>
      <div class="comments-content">
        <div class="author-name">
          <ion-label @click.stop="openProfile(postCommentData!.author.id)">
            {{ postCommentData.author.fullName + ':' }}
          </ion-label>
        </div>
        <div class="author-text">
          <feed-text :text-data="postCommentData.bodyHtml" is-comment />
          <feed-files
            v-if="postCommentData.attachedFiles.count > 0"
            :files-data="postCommentData.attachedFiles.data"
            :post-date="postCommentData.createdAt"
            is-comment-flag
          />
          <feed-wiki
            v-if="postCommentData.attachedWikis.count > 0"
            :wiki-data="postCommentData.attachedWikis.data"
            :created-at="postCommentData.createdAt"
          />
        </div>
        <div class="bottom-block">
          <div class="date">
            <ion-label>
              {{ formatDateHelper(postCommentData.createdAt, 'long') }}
            </ion-label>
          </div>
          <div class="buttons">
            <feed-controls-buttons
              :access="postData.access"
              :only-count="true"
              :data="postCommentData"
              :type-for-buttons="FeedTypeForButtonsEnum.Comment"
              :post-id="postId"
              @onCommentReply="commentReply"
            />
          </div>
        </div>
      </div>

      <feed-comment-menu-button
        :post-comment-data="postCommentData"
        :task-comment-data="taskCommentData"
        :feed-flag="feedFlag"
        :feed-type="feedType"
        :current-user-id="currentUserId"
        :post-id="postId"
        :task-id="taskId"
      />
    </template>

    <!-- Если это задача -->
    <template v-if="taskCommentData">
      <div
        class="author-avatar"
        @click.stop="openProfile(taskCommentData!.author.id)"
      >
        <user-avatar
          :fio="taskCommentData.author.fullName"
          :url="taskCommentData.author.image?.url ?? ''"
          :image="taskCommentData.author.image"
        />
      </div>
      <div class="comments-content">
        <div
          class="author-name"
          @click.stop="openProfile(taskCommentData!.author.id)"
        >
          <ion-label> {{ taskCommentData.author.fullName + ':' }} </ion-label>
        </div>
        <div class="author-text">
          <feed-text :text-data="taskCommentData.text" is-comment />
          <feed-files
            v-if="taskCommentData.files.length > 0"
            :files-data="taskCommentData.files"
            :post-date="taskCommentData.dateCreated"
            is-comment-flag
          />
          <feed-wiki
            v-if="taskCommentData.wikis.length > 0"
            :wiki-data="taskCommentData.wikis"
            :created-at="taskCommentData.dateCreated"
          />
        </div>
        <div class="bottom-block">
          <div class="date">
            <ion-label>
              {{ formatDateHelper(taskCommentData.dateCreated, 'long') }}
            </ion-label>
          </div>
        </div>
      </div>

      <feed-comment-menu-button
        :task-comment-data="taskCommentData"
        :current-user-id="currentUserId"
        :task-id="taskId"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { IonLabel } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import {
  UserAvatar,
  FeedControlsButtons,
  FeedText,
  FeedFiles,
  FeedWiki,
  FeedCommentMenuButton,
} from '@/components';
import {
  FeedFilterTypeEnum,
  FeedFlagEnum,
  FeedTypeForButtonsEnum,
} from '@/enums';
import { formatDateHelper } from '@/helpers';
import { ROUTES_NAME } from '@/router';
import { usePostStore } from '@/store';
import type {
  CommentsDataModel,
  PostEntity,
  TaskManagementTaskCommentDataModel,
} from '@/types';

const postStore = usePostStore();
const props = defineProps({
  postCommentData: {
    type: Object as PropType<CommentsDataModel | undefined>,
    default: undefined,
  },
  taskCommentData: {
    type: Object as PropType<TaskManagementTaskCommentDataModel | undefined>,
    default: undefined,
  },
  currentUserId: {
    type: Number,
    default: 0,
  },
  postId: {
    type: Number,
    default: 0,
  },
  taskId: {
    type: Number,
    default: 0,
  },
  feedFlag: {
    type: String as PropType<FeedFlagEnum>,
    default: FeedFlagEnum.FeedPage,
  },
  feedType: {
    type: String as PropType<FeedFilterTypeEnum | null>,
    default: FeedFilterTypeEnum.AllPublic,
  },
});

// Если пост
const postCommentData: ComputedRef<CommentsDataModel | undefined> = computed(
  () => props.postCommentData
);

//Если задача
const taskCommentData: ComputedRef<
  TaskManagementTaskCommentDataModel | undefined
> = computed(() => props.taskCommentData);

const postData: ComputedRef<PostEntity> = computed(() =>
  postStore.getPostById(props.postId)
);

const router = useRouter();
const openProfile = async (id: number) => {
  await router.push({
    name: ROUTES_NAME.USER_BY_ID,
    params: { id: id },
  });
};

const emit = defineEmits(['onCommentReply']);
const commentReply = (comment: CommentsDataModel) => {
  emit('onCommentReply', comment);
};
</script>
<style scoped lang="scss">
.comments-block {
  position: relative;
  display: flex;
  width: 100%;
  padding-top: 0.5rem;
  &.with-border {
    border-bottom: 1px solid var(--ion-color-light-custom);
  }
  &:hover {
    cursor: default;
  }
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  .author-avatar {
    ion-avatar {
      margin-right: 1rem;
      width: 32px;
      height: 32px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .author-name {
    font-size: 0.9rem;
  }
  .author-name ion-label {
    &:hover {
      cursor: pointer;
      color: var(--ion-color-medium);
    }
  }
  .comments-content {
    border-radius: 4px;
    width: 100%;
    margin-bottom: 0.5rem;
    min-width: 0;
    overflow: hidden;
    .author-text {
      margin: 0.5rem 0;
      ion-text {
        white-space: normal;
        user-select: all;
      }
    }
    .bottom-block {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .date ion-label {
        font-size: 0.8em;
        color: var(--ion-color-medium);
      }
    }
  }

  @include respond-to-min-width(2xl) {
    .author-name {
      font-size: 1rem;
    }

    .comments-content {
      .bottom-block {
        .date ion-label {
          font-size: 0.9rem;
        }
      }
    }
  }
}
</style>
