import { cloneDeep, orderBy, sumBy, unionBy } from 'lodash';
import { defineStore } from 'pinia';

import {
  ChainTypeEnum,
  MessageDeliveryStatusEnum,
  MessageTypeEnum,
} from '@/enums';
import { $api } from '@/services';
import type { EntityState } from '@/store';
import { useChatStore } from '@/store';
import type {
  ErrorMessageModel,
  LastMessageModel,
  MessageChainEntity,
  ResponseChainsModel,
  ResponseErrorModel,
  ResponseUnreadModel,
  ResponseSuccessModel,
  ResponseChainModel,
  ResponseMessagesModel,
} from '@/types';

interface MessengerState extends EntityState<MessageChainEntity> {
  isInit: boolean;
  isActive: boolean;
  loadMoreUrl: string | null;
  disableLoadMore: boolean;
}

export const useMessengerStore = defineStore({
  id: 'messenger',
  state: (): MessengerState => ({
    data: [],
    errors: [],
    loading: false,
    isInit: false,
    isActive: true,
    loadMoreUrl: null,
    disableLoadMore: false,
  }),
  getters: {
    autocomplete: (state) => (text: string) => {
      text = text.toLowerCase();
      return text.length > 0
        ? state.data.filter((n: MessageChainEntity) =>
            n.title.toLowerCase().includes(text)
          )
        : state.data;
    },
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getByType:
      (state) =>
      (type: ChainTypeEnum): MessageChainEntity[] =>
        orderBy(
          state.data.filter((f: MessageChainEntity) => f.chainType === type),
          ['lastMessage.createdAt'],
          ['desc']
        ),
    getLastMessageId: (state) => () => {
      const maxId = Math.max(...state.data.map((n) => n.lastMessage.id));
      return isFinite(maxId) ? maxId : null;
    },
    isEmptyList: (state) => (): boolean => {
      return state.data.length === 0;
    },
    getChainById:
      (state) =>
      (chainId: number): MessageChainEntity | undefined => {
        return state.data.find((n) => n.chainId === chainId);
      },
    getUnreadMessagesCount: (state): number =>
      sumBy(state.data, (n) => n.unreadMessagesCount),
    getLoadMoreUrl: (state) => () => state.loadMoreUrl,
  },
  actions: {
    /**
     * Enums for sorting order and types.
     *
     * @enum {string} SortingOrder - Defines the sorting order
     * @property {string} ASC - Sort in ascending order
     * @property {string} DESC - Sort in descending order
     *
     * @enum {string} SortingType - Defines the sorting type
     * @property {string} RECENTLY_UPDATED - Sort by recently updated chains
     * @property {string} ALPHABETIC - Sort alphabetically by chain title
     *
     * In the future, sorting should be implemented on the backend to optimize performance.
     * The current sorting logic is a temporary solution implemented on the frontend.
     *
     * Ideally, when calling the `getChains` and `loadMore` API methods, we should pass
     * sorting parameters (type and order) to handle sorting on the server side.
     * For both `getChains` and `loadMore`, the sorting type and order should be passed
     * to the backend to apply server-side sorting.

      export const enum SortingOrder = {
        ASC = 'asc',
        DESC = 'desc'
      };

      export const enum SortingType = {
        RECENTLY_UPDATED = 'recentlyUpdated',
        ALPHABETIC = 'alphabetic'
      };
    */

    /**
     * Fetches chains from the server and sorts them based on the lastMessage.createdAt.
     *
     * @note Currently, sorting is done on the frontend as a temporary solution.
     * In the future, sorting should be handled on the backend, with the API allowing
     * passing sorting parameters (type and order).
     */
    async getChains(): Promise<void> {
      this.$reset();
      this.loading = true;
      const response = await $api.messenger.getChains();

      if (response.statusCode === 200) {
        const model = response as ResponseChainsModel;

        this.data = model.data;

        this.data = sortByLastMessageDate(this.data);

        this.loadMoreUrl = model.loadMoreUrl;
        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
    },

    /**
     * Loads more chains from the provided URL, merges them with existing data,
     * and sorts the merged data by lastMessage.createdAt.
     *
     * @note Similar to getChains, sorting is currently handled on the frontend. In future implementations,
     * sorting parameters (type and order) should be passed to the API for server-side sorting.
     */
    async loadMore(url: string): Promise<boolean> {
      const response = await $api.messenger.getByUrl(url);

      if (response.statusCode === 200) {
        const model = response as ResponseChainsModel;

        this.data = sortByLastMessageDate(mergeById(this.data, model.data));
        this.loadMoreUrl = model.loadMoreUrl;
        this.errors = [];

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    setDisableLoadMore(state: boolean) {
      this.disableLoadMore = state;
    },
    async chainById(chainId: number): Promise<void> {
      const response = await $api.messenger.getChainById(chainId);

      if (response.statusCode === 200) {
        const model = response as ResponseChainModel;

        const chain = model.data;
        const index = this.data.findIndex((n) => n.chainId === chain.chainId);
        if (~index) {
          this.data[index] = cloneDeep(chain);
        } else {
          this.data.unshift(chain);
        }
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return;
    },
    async chainByUserId(userId: number): Promise<void> {
      const response = await $api.messenger.getByUserId(userId);

      if (response.statusCode === 200) {
        const model = response as ResponseMessagesModel;
        const chainId = model.data[0].chainId;
        await this.chainById(chainId);
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return;
    },
    async updateUnreadCounter() {
      const chatStore = useChatStore();

      const response = await $api.messenger.getUnread(this.getLastMessageId());
      if (response.statusCode === 200) {
        const model = response as ResponseUnreadModel;

        model.data.forEach((m) => {
          const index = this.data.findIndex(
            ({ chainId }) => chainId === m.chainId
          );
          if (~index) {
            this.updateLastMessage(
              m,
              m.status !== MessageDeliveryStatusEnum.Delivered
            );
          }
        });

        // If user have an active chain and last message is not the same as in the store
        if (chatStore.chain) {
          const currentChainId = chatStore.chain.chainId;
          if (!currentChainId) return;

          const currentChain = chatStore.chats[currentChainId];
          if (!currentChain) return;

          const currentChainMessages = currentChain.data;
          const lastMessageInCurrentChain = currentChainMessages.at(-1);

          if (
            lastMessageInCurrentChain?.id !==
            this.getChainById(currentChainId)?.lastMessage.id
          ) {
            await chatStore.updateChain(chatStore.chain, true);
          }
        }
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
    },
    updateLastMessage(message: LastMessageModel, isNew: boolean) {
      const index = this.data.findIndex(
        ({ chainId }) => chainId === message.chainId
      );

      if (~index) {
        this.data[index].lastMessage = message;
        const chatStore = useChatStore();
        chatStore.updated = new Date().getTime();

        if (
          isNew &&
          message.type !== MessageTypeEnum.Init &&
          message.type !== MessageTypeEnum.CreateGroup
        ) {
          this.data[index].unreadMessagesCount++;
        }
      }

      this.errors = [];
    },
    async changeChainType(id: number, type: ChainTypeEnum): Promise<boolean> {
      const chatStore = useChatStore();
      let response: ResponseSuccessModel | ResponseErrorModel | null = null;
      if (type === ChainTypeEnum.Active) {
        response = await $api.messenger.moveToActive(id);
      }
      if (type === ChainTypeEnum.Archive) {
        response = await $api.messenger.moveToArchive(id);
      }

      if (response?.statusCode === 200) {
        const index = this.data.findIndex((n) => n.chainId === id);
        this.data[index].chainType = type;
        if (chatStore.chain?.chainId === id) {
          chatStore.chain.chainType = type;
        }
        return true;
      }

      if (response?.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async muteChainChange(chainId: number, mute: boolean): Promise<boolean> {
      const chatStore = useChatStore();
      const response = await $api.messenger.muteChainChange(chainId, mute);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.chainId === chainId);
        this.data[index].muted = mute;
        if (chatStore.chain?.chainId === chainId) {
          chatStore.chain.muted = mute;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async deleteChain(chainId: number): Promise<boolean> {
      const response = await $api.messenger.deleteChain(chainId);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.chainId === chainId);
        this.data.splice(index, 1);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async exitChain(chainId: number): Promise<boolean> {
      const response = await $api.messenger.exitChain(chainId);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.chainId === chainId);
        this.data[index].isInChain = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async renameChain(chainId: number, chainTitle: string): Promise<boolean> {
      const response = await $api.messenger.renameChain(chainId, chainTitle);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.chainId === chainId);
        this.data[index].title = chainTitle;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    resetUnreadMessagesCount(chainId: number) {
      const index = this.data.findIndex((n) => n.chainId === chainId);
      if (this.data[index]) {
        this.data[index].unreadMessagesCount = 0;
      }
    },
    updateMessengerData(a: MessageChainEntity[], b: MessageChainEntity[]) {
      // Merge and sort the new data with the existing data
      this.data = sortByLastMessageDate(mergeById(a, b));
    },
  },
  persist: true,
});

/**
 * Merges two arrays of MessageChainEntity by chainId.
 * Gives priority to the objects from the second array (new data).
 *
 * @param a - The first array of MessageChainEntity (existing data)
 * @param b - The second array of MessageChainEntity (new data)
 * @returns A merged array of MessageChainEntity
 */
const mergeById = (a: MessageChainEntity[], b: MessageChainEntity[]) => {
  // Merge two arrays by chainId, prioritizing new data (b)
  return unionBy(b, a, 'chainId');
};

/**
 * Sorts an array of MessageChainEntity by the lastMessage's createdAt date.
 * The most recent messages will appear first.
 *
 * @param chains - The array of MessageChainEntity to be sorted
 * @returns A sorted array by lastMessage.createdAt in descending order
 */
const sortByLastMessageDate = (chains: MessageChainEntity[]) => {
  return chains.sort((chainA, chainB) => {
    const dateA = new Date(chainA.lastMessage.createdAt).getTime();
    const dateB = new Date(chainB.lastMessage.createdAt).getTime();
    return dateB - dateA; // Sort in descending order
  });
};
