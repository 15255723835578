import type { DocumentExtensionEnum, WikiMajorFilterEnum } from '@/enums';
import axios from '@/services/axios';
import type {
  CreateWikiPayload,
  UpdateWikiPayload,
  UpdateWikiDraftPayload,
  ResponseSuccessModel,
  ResponseErrorModel,
  ResponseWikiDraftModel,
  ResponseWikiCreateModel,
  ResponseWikiRelationsModel,
  ResponseWikiHistoryModel,
  ResponseWikiHistoryByIdModel,
  ResponseWikiModel,
  ResponseLockStatusModel,
  ResponseWikiDraftUpdateModel,
  ResponseWikisModel,
  ResponseWikiFollowersModel,
  ResponseWikiTemplateModel,
  ResponseWikiTemplatesModel,
  SaveWikiTemplateModel,
  CreateWikiTemplateModel,
} from '@/types';

export class WikiApiService {
  async getWikiById(
    wikiId: number
  ): Promise<ResponseWikiModel | ResponseErrorModel> {
    return axios.get(`/wiki/byId/${wikiId}`);
  }

  async create(
    payload: CreateWikiPayload
  ): Promise<ResponseWikiCreateModel | ResponseErrorModel> {
    return axios.post('/wiki/create', payload);
  }

  async update(
    payload: UpdateWikiPayload
  ): Promise<ResponseWikiCreateModel | ResponseErrorModel> {
    return axios.post('/wiki/update', payload);
  }

  async download(
    id: number,
    documentExtension: DocumentExtensionEnum
  ): Promise<Blob | ResponseErrorModel> {
    return axios.get(`/storage/wiki/${id}?type=${documentExtension}`, {
      responseType: 'blob',
    });
  }

  async markAsOfficial(
    id: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/markAsOfficial/${id}`);
  }

  async delete(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/delete/${id}`);
  }

  async deleteWithReplace(
    id: number,
    relationWikiId: number | null = null,
    relationFileId: number | null = null
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    if (relationWikiId) {
      return axios.delete(
        `/wiki/deleteWithReplace/${id}?relationWikiId=${relationWikiId}`
      );
    }
    return axios.delete(
      `/wiki/deleteWithReplace/${id}?relationFileId=${relationFileId}`
    );
  }

  //* Relations
  //NOTE: Get Related to Wiki
  async getRelations(
    id: number
  ): Promise<ResponseWikiRelationsModel | ResponseErrorModel> {
    return axios.get(`/wiki/relations/${id}`);
  }

  //! NOTE: Add Related to Wiki
  /**
     * 				{
            "name": "Add Related to Wiki",
            "request": {
              "method": "POST",
              "header": [],
              "url": {
                "raw": "{{base_url}}/api/Wiki/AddRelation/{{WIKIID}}",
                "host": [
                  "{{base_url}}"
                ],
                "path": [
                  "api",
                  "Wiki",
                  "AddRelation",
                  "{{WIKIID}}"
                ],
                "query": [
                  {
                    "key": "relationFileId",
                    "value": "{{FILEID}}",
                    "disabled": true
                  },
                  {
                    "key": "relationWikiId",
                    "value": "{{WIKIID2}}",
                    "disabled": true
                  }
                ]
              }
            },
            "response": []
          },
     */
  async addRelation(
    id: number,
    relationFileId: number | null = null,
    relationWikiId: number | null = null
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(
      `/wiki/addRelation/${id}?relationFileId=${relationFileId}&relationWikiId=${relationWikiId}`
    );
  }

  //! NOTE: Remove Related from Wiki
  /**
           * 				{
                  "name": "Remove Related from Wiki",
                  "request": {
                    "method": "DELETE",
                    "header": [],
                    "url": {
                      "raw": "{{base_url}}/api/Wiki/RemoveRelation/{{WIKIID}}",
                      "host": [
                        "{{base_url}}"
                      ],
                      "path": [
                        "api",
                        "Wiki",
                        "RemoveRelation",
                        "{{WIKIID}}"
                      ],
                      "query": [
                        {
                          "key": "relationFileId",
                          "value": "{{FILEID}}",
                          "disabled": true
                        },
                        {
                          "key": "relationWikiId",
                          "value": "{{WIKIID2}}",
                          "disabled": true
                        }
                      ]
                    }
                  },
                  "response": []
                },
           */
  async removeRelation(
    id: number,
    relationFileId: number | null = null,
    relationWikiId: number | null = null
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(
      `/wiki/removeRelation/${id}?relationFileId=${relationFileId}&relationWikiId=${relationWikiId}`
    );
  }
  async getFollowers(
    id: number
  ): Promise<ResponseWikiFollowersModel | ResponseErrorModel> {
    return axios.get(`/wiki/followers/${id}`);
  }
  async follow(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/${id}/follow`);
  }
  async unfollow(
    id: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/${id}/unfollow`);
  }

  //* History
  //NOTE: Get Wiki Revision History
  async getHistory(
    id: number
  ): Promise<ResponseWikiHistoryModel | ResponseErrorModel> {
    return axios.get(`/wiki/history/${id}`);
  }

  //NOTE: Get Wiki Revision
  async getHistoryById(
    id: number
  ): Promise<ResponseWikiHistoryByIdModel | ResponseErrorModel> {
    return axios.get(`/wiki/historyById/${id}`);
  }

  //NOTE: Wiki history by date
  //NOTE: Method returns the closest wiki history by provided date
  async getHistoryByDate(
    id: number,
    date: string,
    majorFilter: WikiMajorFilterEnum
  ): Promise<ResponseWikiHistoryByIdModel | ResponseErrorModel> {
    return axios.get(
      `/wiki/historyByDate/${id}?date=${date}&majorFilter=${majorFilter}`
    );
  }

  //!: Update Wiki Revision
  //NOTE: Method now has a parameter isMajor which signals the saving version is major
  async updateHistoricalWiki(
    payload: UpdateWikiPayload
  ): Promise<ResponseWikiCreateModel | ResponseErrorModel> {
    return axios.post('/wiki/updateHistory/${id}', payload);
  }

  //! NOTE: Rollback Wiki
  async rollback(
    wikiId: number,
    historyId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/rollback/${wikiId}?historyId=${historyId}`);
  }

  //* Drafts
  //NOTE: Get Your Latest Wiki Draft
  async getCurrentDraft(): Promise<
    ResponseWikiDraftModel | ResponseErrorModel
  > {
    return axios.get('/wiki/currentTemp');
  }

  //NOTE: Get Existing Wiki Draft
  async getDraftById(
    id: number
  ): Promise<ResponseWikiDraftModel | ResponseErrorModel> {
    return axios.get(`/wiki/tempById/${id}`);
  }

  //NOTE: Draft a Wiki
  async updateCurrentDraft(
    payload: UpdateWikiDraftPayload
  ): Promise<ResponseWikiDraftUpdateModel | ResponseErrorModel> {
    return axios.post('/wiki/updateTemp', payload);
  }

  //! NOTE: Discard Wiki Draft
  async deleteDraftById(
    id: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/deleteTemp/${id}`);
  }

  //#region Templates

  // NOTE: Get Wiki Template
  async getTemplateById(
    id: number
  ): Promise<ResponseWikiTemplateModel | ResponseErrorModel> {
    return axios.get(`/wiki/templateById/${id}`);
  }

  //NOTE: Get Wiki Templates
  async getTemplates(): Promise<
    ResponseWikiTemplatesModel | ResponseErrorModel
  > {
    return axios.get('/wiki/templates?page=1');
  }

  //NOTE: Get Wiki Templates Load More
  async getTemplatesLoadMore(
    loadMoreUrl: string
  ): Promise<ResponseWikiTemplatesModel | ResponseErrorModel> {
    return axios.get(loadMoreUrl);
  }

  //NOTE: Create Wiki Template
  async createTemplate(
    data: CreateWikiTemplateModel
  ): Promise<ResponseWikiTemplateModel | ResponseErrorModel> {
    return axios.post('/wiki/createTemplate', data, {
      headers: {
        withoutToast: true,
      },
    });
  }

  //NOTE: Update Wiki Template
  async updateTemplateById(
    data: SaveWikiTemplateModel
  ): Promise<ResponseWikiTemplateModel | ResponseErrorModel> {
    return axios.post(`/wiki/updateTemplate`, data, {
      headers: {
        withoutToast: true,
      },
    });
  }

  //NOTE: Discard Wiki Template
  async deleteTemplateById(
    id: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/deleteTemplate/${id}`);
  }

  //#endregion

  //* Tags
  //NOTE: Add Tags to Wiki
  async addTag(
    id: number,
    tagTexts: string[]
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/wiki/addTag', { id, tagTexts });
  }

  //NOTE: Remove Tag from Wiki
  async removeTag(
    id: number,
    tagId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/removeTag/${id}?tagId=${tagId}`);
  }

  //* Lock
  //! NOTE: Lock Wiki (while editing)
  async lockEdit(
    id: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/lockEdit/${id}`);
  }

  //! NOTE: Unlock Wiki (after finished editing)
  async unlockEdit(
    id: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/unlockEdit/${id}`);
  }

  //! NOTE: Check Wiki Lock Status
  async checkLock(
    id: number
  ): Promise<ResponseLockStatusModel | ResponseErrorModel> {
    return axios.get(`/wiki/checkLock/${id}`);
  }

  //* Contributions
  //! NOTE: Get Wikis by User (Contributions)
  /**
   * 				{
					"name": "Get Wikis by User (Contributions)",
					"request": {
						"method": "GET",
						"header": [
							{
								"key": "Accept",
								"value": "text/plain"
							}
						],
						"url": {
							"raw": "{{base_url}}/api/Wiki/Contributions?userId={{USERID}}",
							"host": [
								"{{base_url}}"
							],
							"path": [
								"api",
								"Wiki",
								"Contributions"
							],
							"query": [
								{
									"key": "userId",
									"value": "{{USERID}}"
								},
								{
									"key": "page",
									"value": "1",
									"description": "(optional)",
									"disabled": true
								},
								{
									"key": "pageSize",
									"value": "20",
									"description": "(optional)",
									"disabled": true
								},
								{
									"key": "search",
									"value": "{{$randomLoremWord}}",
									"description": "(optional)",
									"disabled": true
								},
								{
									"key": "sort",
									"value": "date",
									"description": "(optional) date",
									"disabled": true
								},
								{
									"key": "sortDirection",
									"value": "desc",
									"description": "(optional) asc | desc",
									"disabled": true
								},
								{
									"key": "readStatus",
									"value": "all",
									"description": "(optional) all | unread | read",
									"disabled": true
								}
							]
						}
					},
					"response": []
				}
   */
  async getContributionsByUserId(
    userId: number
  ): Promise<ResponseWikiModel[] | ResponseErrorModel> {
    // "raw": "{{base_url}}/api/Wiki/Contributions?userId={{USERID}}",
    return axios.get(`/wiki/contributions?userId=${userId}`);
  }

  //* Search
  async autocomplete(
    searchText: string
  ): Promise<ResponseWikisModel | ResponseErrorModel> {
    return axios.get(`/wiki/autocomplete?searchText=${searchText}`);
  }
}

/**
 * @param requestBody - The request body to send to the Node.js server
 * @returns The response from the Node.js server
 * @description This function makes an API call to the Node.js server to get the Git diff of the two texts
 * @todo Make JWT authentication for the server
 * @todo Add error handling
 * @todo Add loading handling
 * @todo Refactor the function to use the axios library
 */
export const getDiff = async (requestBody: {
  originalText: string;
  updatedText: string;
}): Promise<string | null> => {
  const url = import.meta.env.VITE_NODE_SERVER_URL_DEV + '/wiki/gitDiff/';
  // const url = 'http://localhost:3000/wiki/gitDiff/';

  // console.log('≥≥≥Request body:', requestBody); //! DEBUG

  // const mockRequestBody = {
  //   originalText: 'Hello World!',
  //   updatedText: 'Hello World! Hello World!',
  // };

  let resultString = '';

  //TODO: Make JWT authentication for the server
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
    // body: JSON.stringify(mockRequestBody),
  })
    .then(async (response: Response) => {
      if (response.ok) {
        await response.text().then((data) => {
          resultString = data;
        });
      } else {
        console.error('Fetch Error:', response);
        return null;
      }
    })
    .catch((error) => {
      console.error('Fetch Error:', error);
      return null;
    });

  return resultString;
};
